import { Card, CardContent, Chip, Typography } from '@material-ui/core';
import React from 'react';
import { Loader } from '../../components/Loader';
import { Trend } from '../../components/Trend';
import {
  aggregateDailyCounters,
  ICounter,
  IDailyCounter
} from '../../domainTypes/analytics';
import { styled } from '../../emotion';
import {
  lastDays,
  multiplyTimeframe,
  useCountsInTimeframeForProductPg
} from '../../services/analytics';
import { useMappedLoadingValue } from '../../services/db';
import { splitInHalf } from '../../services/list';

type Props = {
  style?: React.CSSProperties;
};

const ChipContainer = styled('div')`
  display: flex;
  justify-content: center;
`;

const StyledChip = styled(Chip)`
  background-color: ${(p) => p.theme.palette.grey.A100} !important;
`;

const ClickNumber: React.FC = ({ children }) => (
  <span style={{ fontWeight: 'bold' }}>{children}</span>
);

const ClickCountItem = styled('div')`
  margin-top: ${(p) => p.theme.spacing(4)}px;
  text-align: center;
`;

const TrendInnerContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const ClickCountChart: React.FC<{
  counters: void | IDailyCounter[];
  loading: boolean;
  label: string;
}> = React.memo(({ counters, loading, label }) => {
  const [comparisonSums, sums] = sumCountsWithComparison(counters || [], true);

  return (
    <Card>
      <CardContent>
        <ChipContainer>
          <StyledChip color="default" label={label} />
        </ChipContainer>
        <ClickCountItem>
          {loading ? (
            <Loader height={82} style={{ margin: '0 auto' }} />
          ) : (
            <>
              <TrendInnerContainer>
                <Trend
                  values={[comparisonSums.clicked, sums.clicked]}
                  label="right"
                  labelSize={24}
                  arrowSize={24}
                />
              </TrendInnerContainer>
              <Typography variant="body2" component="p">
                <ClickNumber>{sums.clicked.toLocaleString()}</ClickNumber>{' '}
                outbound clicks
              </Typography>
            </>
          )}
        </ClickCountItem>
      </CardContent>
    </Card>
  );
});

const sumCountsWithComparison = (
  counts: IDailyCounter[],
  compare: boolean
): [ICounter, ICounter] => {
  const [a, b] = compare ? splitInHalf(counts) : [[], counts];
  return [aggregateDailyCounters(a), aggregateDailyCounters(b)];
};

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(6)}px;

  ${(p) => p.theme.breakpoints.down('md')} {
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr;
  }
`;

const getTf = (days: number) => multiplyTimeframe(lastDays(days), 2);

export const ClickCountCardProductPg: React.FC<
  Props & { spaceId: string; productId: string }
> = ({ style, spaceId, productId }) => {
  const [d, loading] = useMappedLoadingValue(
    useCountsInTimeframeForProductPg(spaceId, productId, getTf(30)),
    (cs) => {
      return {
        counters1: cs.slice(-2),
        counters7: cs.slice(-14),
        counters30: cs
      };
    }
  );
  return (
    <Grid style={style}>
      <ClickCountChart
        label="Yesterday"
        counters={d && d.counters1}
        loading={loading}
      />
      <ClickCountChart
        label="Last 7 days"
        counters={d && d.counters7}
        loading={loading}
      />
      <ClickCountChart
        label="Last 30 days"
        counters={d && d.counters30}
        loading={loading}
      />
    </Grid>
  );
};
