import { Card, IconButton, Tooltip, Typography } from '@material-ui/core';
import { compact, intersection, isEqual, truncate } from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useMemo, useState } from 'react';
import {
  ArrowRightCircle,
  DownloadCloud,
  Maximize as IconExpand,
  Minimize as IconCollapse
} from 'react-feather';
import { Link } from 'react-router-dom';
import { ChartCard } from '../../../../components/Charts/ChartCard';
import {
  Data as EarningsBarChartData,
  limitAndSort
} from '../../../../components/Charts/EarningsChart';
import { EarningsChartCard as EarningsBarChartCardInner } from '../../../../components/Charts/EarningsChartCard';
import { ChartMode } from '../../../../components/Charts/EarningsChartCard/ChartModeSelector';
import { GraphMode } from '../../../../components/Charts/EarningsChartCard/GraphModeSelector';
import { DeviceIconWithLabel } from '../../../../components/DeviceIcon';
import {
  applySorterOnGroups,
  Group,
  UNKNOWN
} from '../../../../components/GroupableList';
import { InlineLoader, Loader } from '../../../../components/Loader';
import { SearchInput } from '../../../../components/SearchInput';
import {
  SiteSelector,
  UNATTRIBUTED
} from '../../../../components/SiteSelector';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import { SortSelector } from '../../../../components/Table/SortSelector';
import { TimeframeDurationChip } from '../../../../components/TimeframeDurationChip';
import {
  TimeframePickerDense,
  useStandardOptions
} from '../../../../components/TimeframePicker/LegacyPickers';
import { CurrencyCode } from '../../../../domainTypes/currency';
import { EMPTY_ARR, EMPTY_OBJ } from '../../../../domainTypes/emptyConstants';
import {
  // aggregateSales,
  EarningsArgsGroupedInTimeframe,
  EarningsArgsGroupedInTimeframeAsTimeseries,
  EarningsRespGroupedInTimeframe,
  EarningsRespGroupedInTimeframeAsTimeseries,
  EARNING_MINIMAL_FIELD_SET,
  IEarning,
  PayoutStatus,
  PAYOUT_STATUSES,

  // ITrackedSale,
  SalesFilterArgs,
  SaleStatus,
  SaleType,
  SALE_STATUSES,
  SALE_TYPES,
  SALE_TYPES_WITHOUT_BONUS,
  toDailyEarningFromMinimal,
  toEarningFromMinimal,
  TransactionsArgs
} from '../../../../domainTypes/performance';
import { interpolatePath, PATHS } from '../../../../domainTypes/routes';
import { Device } from '../../../../domainTypes/tracking';
import { styled } from '../../../../emotion';
import { useLegacyTimeframe } from '../../../../hooks/timeframe';
import { useDialogState } from '../../../../hooks/useDialogState';
import { useErrorLoggerWithLabel } from '../../../../hooks/useErrorLogger';
import { usePromise } from '../../../../hooks/usePromise';
import { useWhatChanged } from '../../../../hooks/useWhatChanged';
import { Centered } from '../../../../layout/Centered';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import { Section } from '../../../../layout/Section';
import {
  queryParamToList,
  setToQueryParam,
  useNullableStringSetQueryParam,
  useQueryParam,
  useRoutes,
  useStringQueryParam,
  useTypedStringQueryParam
} from '../../../../routes';
// import { allTime } from '../../../../services/analytics';
import { useCurrentUser } from '../../../../services/currentUser';
import { LoadingValue, useMappedLoadingValue } from '../../../../services/db';
import { debuggedShallowEqual } from '../../../../services/debug';
import { callFirebaseFunction } from '../../../../services/firebaseFunctions';
import { getKnownPartnerForKey } from '../../../../services/partner';
import { getProductByIdPg } from '../../../../services/products';
import {
  useAdvertiserTimeseriesData,
  useEarningsSingle
} from '../../../../services/sales/earnings';
import { useSalesConverted } from '../../../../services/sales/sales';
import { getActiveDomainUrls } from '../../../../services/space';
import { msToTimeframe, timeframeToMs } from '../../../../services/time';
import { withoutProtocol } from '../../../../services/url';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import { CF } from '../../../../versions';
import { EmptyState } from '../../components/EmptyState';
import { ImportDialog } from '../../components/ImportDialog';
import { PerformancePageBody } from '../../components/PerformancePageBody';
import { SalesFilter } from '../../components/SalesFilter';
import {
  ColumnName,
  COLUMNS,
  DEFAULT_VISIBLE_COLUMNS,
  GROUPERS,
  GrouperType,
  SalesGrouper,
  SalesGroupHeader,
  SalesList,
  SalesSorter,
  SORTERS
} from '../../components/SalesList';
import { TotalsCard } from '../../components/TotalsCard';
import { TransactionExportDialog } from '../../components/TransactionExportDialog';
import {
  useKnownAdvertisers,
  useKnownPartners,
  useKnownPayoutIds
} from '../../services/hooks';
import { useHasAnyReports } from '../../services/report';
import { useTotals } from '../../services/sale';
import { IPreset, PRESETS } from './presets';

const Grid = styled('div')`
  display: grid;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(6)}px;
  grid-template-columns: 1fr 2fr;
  margin-bottom: ${(p) => p.theme.spacing(6)}px;

  ${(p) => p.theme.breakpoints.down('md')} {
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    margin-bottom: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr 2fr;
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
    margin-bottom: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr;
  }
`;

const ALL_COLLAPSED = { defaultValue: true, byKey: EMPTY_OBJ };
const ALL_EXPANDED_BESIDES_UNKNOWN = {
  defaultValue: false,
  byKey: { [UNKNOWN]: true }
};

const queryParamToGroup = (p: string | undefined | null) => {
  if (p === 'none') {
    return null;
  }
  const t = p as GrouperType | undefined | null;
  return t && GROUPERS[t] ? GROUPERS[t] : GROUPERS.date;
};

const isSameColumns = (a: ColumnName[], b: ColumnName[]) => {
  if (a.length !== b.length) {
    return false;
  }
  const toString = (x: ColumnName[]) => [...x].sort().join(',');
  return toString(a) === toString(b);
};

const TimeframePickerStandalone = () => {
  const { options } = useStandardOptions();
  const [timeframe, setTimeframe] = useLegacyTimeframe();
  return (
    <TimeframePickerDense
      value={timeframe}
      onChange={setTimeframe}
      options={options}
    />
  );
};

const ExpandOrCollapse = ({
  allCollapsed,
  onChange
}: {
  allCollapsed: boolean;
  onChange: (nextState: {
    defaultValue: boolean;
    byKey: { [key: string]: boolean };
  }) => void;
}) => {
  return (
    <Tooltip title={allCollapsed ? 'Expand all groups' : 'Collapse all groups'}>
      <IconButton
        onClick={() =>
          onChange(allCollapsed ? ALL_EXPANDED_BESIDES_UNKNOWN : ALL_COLLAPSED)
        }
      >
        {allCollapsed ? <IconExpand size={18} /> : <IconCollapse size={18} />}
      </IconButton>
    </Tooltip>
  );
};

const useNetworkTimeseriesData = (
  spaceId: string,
  q: SalesFilterArgs,
  currency: CurrencyCode
): LoadingValue<{
  dates: { start: number; end: number; tz: string };
  data: EarningsBarChartData[];
}> => {
  const query: EarningsArgsGroupedInTimeframeAsTimeseries = useMemo(() => {
    return {
      type: 'groupedInTimeframeAsTimeseries',
      d: {
        groupBy: ['partner_key'],
        ...q,
        currency,
        fields: EARNING_MINIMAL_FIELD_SET.COMMISSIONS_AND_SALES_VOLUME
      }
    };
  }, [q, currency]);
  return useMappedLoadingValue(
    useEarningsSingle<EarningsRespGroupedInTimeframeAsTimeseries>(
      spaceId,
      query,
      currency
    ),
    (r) => {
      console.log('network timeseries', r);
      return {
        dates: r.res.q.dates,
        data: limitAndSort(
          compact(
            r.res.d.map<EarningsBarChartData | null>((x) => {
              const partnerKey = x.group['partner_key'];
              const partner = getKnownPartnerForKey(partnerKey);
              if (!partner) {
                return null;
              }
              return {
                container: {
                  key: partner.key,
                  color: partner.color,
                  label: partner.name
                },
                earnings: x.ds.map(toDailyEarningFromMinimal)
              };
            })
          ),
          15
        )
      };
    }
  );
};

type EarningsBarChartQ = SalesFilterArgs & {
  dates: {
    start: number;
    end: number;
    tz: string;
    column?: 'sale_date' | 'click_or_sale_date';
  };
};

interface EarningsBarChartCardProps {
  spaceId: string;
  q: EarningsBarChartQ;
  currency: CurrencyCode;
  style?: React.CSSProperties;
  graphMode: GraphMode;
  setGraphMode: (nextGraphMode: GraphMode) => void;
  chartMode: ChartMode;
  setChartMode: (nextGraphMode: ChartMode) => void;
}

const EarningsBarChartCardNetworks = ({
  spaceId,
  q,
  currency,
  style,
  graphMode,
  setGraphMode,
  chartMode,
  setChartMode
}: EarningsBarChartCardProps) => {
  const [d, loading] = useNetworkTimeseriesData(spaceId, q, currency);
  return (
    <EarningsBarChartCardInner
      data={d ? d.data : undefined}
      loading={loading}
      style={style}
      currency={currency}
      graphMode={graphMode}
      setGraphMode={setGraphMode}
      chartMode={chartMode}
      setChartMode={setChartMode}
      timeframe={msToTimeframe(d ? d.dates : q.dates)}
    />
  );
};

const EarningsBarChartCardAdvertisers = ({
  spaceId,
  q,
  currency,
  style,
  graphMode,
  setGraphMode,
  chartMode,
  setChartMode
}: EarningsBarChartCardProps) => {
  const [d, loading] = useAdvertiserTimeseriesData(spaceId, q, currency);
  return (
    <EarningsBarChartCardInner
      data={d ? d.data : undefined}
      loading={loading}
      style={style}
      currency={currency}
      graphMode={graphMode}
      setGraphMode={setGraphMode}
      chartMode={chartMode}
      setChartMode={setChartMode}
      timeframe={msToTimeframe(d ? d.dates : q.dates)}
    />
  );
};

const EarningsBarChartCard = ({
  graphMode,
  ...props
}: EarningsBarChartCardProps) => {
  if (graphMode === 'platform') {
    return <EarningsBarChartCardNetworks {...props} graphMode={graphMode} />;
  }
  if (graphMode === 'advertiser') {
    return <EarningsBarChartCardAdvertisers {...props} graphMode={graphMode} />;
  }
  return null;
};

const translateGrouperType = (type: GrouperType) => {
  switch (type) {
    case 'date':
      return 'sale_date';
    case 'page':
      return 'page_url';
    case 'origin':
      return 'origin';
    case 'productClicked':
      return 'product_id';
    case 'productSold':
      return 'partner_product_name';
    case 'device':
      return 'device';
    case 'partner':
      return 'partner_key';
    case 'advertiser':
      return 'advertiser_name';
    case 'subId':
      return 'tracking_label';
    default:
      return 'sale_date';
  }
};

export const useGroupedEarnings = (
  spaceId: string,
  groupBy: GrouperType,
  q: SalesFilterArgs,
  currency: CurrencyCode
) => {
  const query: EarningsArgsGroupedInTimeframe = useMemo(
    () => ({
      type: 'groupedInTimeframe',
      d: {
        ...q,
        groupBy: [translateGrouperType(groupBy)],
        currency,
        fields: EARNING_MINIMAL_FIELD_SET.COMMISSIONS_VOLUME_AND_TX_COUNT
      }
    }),
    [q, groupBy, currency]
  );
  return useMappedLoadingValue(
    useEarningsSingle<EarningsRespGroupedInTimeframe>(spaceId, query, currency),
    (x) => {
      console.log('groupedEarnings', x);
      return x.res.d.map((g) => {
        const field = translateGrouperType(groupBy);
        const label = g.group[field] || 'Unknown';
        return {
          label,
          earnings: toEarningFromMinimal(g.d),
          field
        };
      });
    }
  );
};

const NoMatchForCriteria = () => (
  <Centered height={400}>
    <div style={{ textAlign: 'center' }}>
      <Typography variant="h4" component="p" paragraph>
        We didn't find any matches for your criteria!
      </Typography>
      <Typography variant="body1" component="p">
        Try increasing the date range for a chance at finding sales that meet
        this criteria.
      </Typography>
    </div>
  </Centered>
);

const NoMatchForSearch = () => (
  <Centered height={400}>
    <div style={{ textAlign: 'center' }}>
      <Typography variant="h4" component="p" paragraph>
        We didn't find any matches for your search!
      </Typography>
      <Typography variant="body1" component="p">
        Search will look at the product name or tracking label for every sale
        you've made.
      </Typography>
      <Typography variant="body1" component="p">
        Double-check for typos, try increasing the date range, or customize the
        preset to apply custom filters.
      </Typography>
    </div>
  </Centered>
);

const GROUP_MODE_SECTION_LIMIT = 30;
const GroupModeSectionSales = ({
  value,
  field,
  sorter,
  salesFilter,
  spaceId,
  currency,
  columns,
  tz
}: {
  value: string;
  field: string;
  sorter: SalesSorter;
  salesFilter: SalesFilterArgs;
  spaceId: string;
  currency: CurrencyCode;
  columns: Set<ColumnName>;
  tz: string;
}) => {
  const [page, setPage] = useState(1);
  const args = useMemo(() => {
    const a: TransactionsArgs = { ...salesFilter };
    // only date and amount sorters are supported right now!
    if (sorter.key === 'date') {
      a.orderBy = {
        col: 'sale_date',
        dir: sorter.items.dir === 'desc' ? 'DESC' : 'ASC'
      };
    }
    if (sorter.key === 'amount') {
      a.orderBy = {
        col: 'commission',
        dir: sorter.items.dir === 'desc' ? 'DESC' : 'ASC'
      };
    }
    if (field === 'sale_date') {
      a.sale_date = { d: value, tz };
    } else {
      (a as any)[field] = [value];
    }
    a.limit = GROUP_MODE_SECTION_LIMIT;
    a.page = page;
    return a;
  }, [value, field, salesFilter, sorter, page, tz]);
  const [data, loading, error] = useSalesConverted(spaceId, args, currency);
  useErrorLoggerWithLabel('transaction group', error);

  if (!data && loading) {
    return (
      <Card>
        <Loader height={200} />
      </Card>
    );
  }
  if (data) {
    return (
      <>
        <SalesList
          sales={data}
          page={page}
          setPage={setPage}
          pageSize={GROUP_MODE_SECTION_LIMIT}
          currency={currency}
          visibleColumns={columns}
          sorter={sorter}
          paginateExternally
        />
      </>
    );
  }
  return null;
};

const LazyProductNameWithLink = ({ productId }: { productId: string }) => {
  const {
    space: { id: spaceId }
  } = useCurrentUser();
  const [product, loading] = usePromise(() => {
    return getProductByIdPg(spaceId, productId);
  }, [spaceId, productId]);
  return (
    <>
      {product ? truncate(product.name, { length: 50 }) : productId}{' '}
      {loading && <InlineLoader color="inherit" />}
      {loading && ' '}
      <Link
        to={interpolatePath(PATHS.links.details.overview, {
          productId
        })}
      >
        <Tooltip title="Open analytics for this specific link" placement="top">
          <IconButton
            color="primary"
            style={{ position: 'relative', top: '-2px', padding: '4px 4px' }}
          >
            <ArrowRightCircle size={18} />
          </IconButton>
        </Tooltip>
      </Link>
    </>
  );
};

const GroupModeSectionValue = ({
  field,
  value
}: {
  field: string;
  value: string;
}) => {
  if (field === 'partner_key') {
    const partner = getKnownPartnerForKey(value);
    if (partner) {
      return <div>{partner.name}</div>;
    }
  }
  if (field === 'page_url') {
    return <div>{withoutProtocol(value)}</div>; // also show a link to the analytics page?
  }
  if (field === 'device') {
    return <DeviceIconWithLabel size={18} device={value as Device} />;
  }
  if (field === 'sale_date') {
    return <div>{moment(value, 'YYYY-MM-DD').format('MMMM Do (dddd)')}</div>;
  }
  if (field === 'partner_product_name') {
    return <div title={value}>{truncate(value, { length: 80 })}</div>;
  }
  if (field === 'product_id' && value !== UNKNOWN) {
    return <LazyProductNameWithLink productId={value} />;
  }
  return <div>{value}</div>;
};

const GroupModeSection = ({
  value,
  field,
  earnings,
  ...props
}: {
  value: string;
  field: string;
  earnings: IEarning;
  sorter: SalesSorter;
  salesFilter: SalesFilterArgs;
  columns: Set<ColumnName>;

  spaceId: string;
  currency: CurrencyCode;
  tz: string;
}) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Section collapse={collapsed}>
      <SalesGroupHeader
        earnings={earnings}
        expanded={!collapsed}
        onExpand={() => setCollapsed((x) => !x)}
      >
        <GroupModeSectionValue field={field} value={value} />
      </SalesGroupHeader>
      {!collapsed && (
        <GroupModeSectionSales value={value} field={field} {...props} />
      )}
    </Section>
  );
};

const GroupMode = ({
  spaceId,
  salesFilter,
  currency,
  tz,
  selectedPreset,
  search,
  visibleColumns,
  sorter,
  grouper,
  groupsCollapsed,
  setGroupCollapsed
}: {
  spaceId: string;
  salesFilter: SalesFilterArgs;
  currency: CurrencyCode;
  tz: string;
  selectedPreset?: IPreset;
  search: string;
  visibleColumns: Set<ColumnName>;
  sorter: SalesSorter;
  grouper: SalesGrouper | null;
  groupsCollapsed?: {
    defaultValue: boolean;
    byKey: { [key: string]: boolean };
  };
  setGroupCollapsed?: (key: string, collapsed: boolean) => void;
}) => {
  const [data, loading] = useMappedLoadingValue(
    useGroupedEarnings(spaceId, grouper?.key || 'date', salesFilter, currency),
    (groups) => {
      // fulfill the Group<IEarning, ITrackedConvertedSaleWithProduct> interace here
      // so that we can render with the inner components of SalesList easily and
      // use the grouper
      return groups.map<Group<IEarning, any> & { field: string }>((g) => {
        return {
          key: g.label,
          items: [],
          summary: g.earnings,
          field: g.field
        };
      });
    }
  );
  // search operates on groups here maybe!

  const sortedGroups = useMemo(() => {
    if (!data) {
      return null;
    }
    return applySorterOnGroups(sorter, data, sorter.groups.dir);
  }, [data, sorter]);

  return (
    <>
      {!sortedGroups && loading && <Loader height={300} />}
      {sortedGroups && !loading && (
        <>
          {!sortedGroups.length &&
            selectedPreset &&
            search.length === 0 &&
            selectedPreset.emptyState}
          {!sortedGroups.length && search.length === 0 && (
            <NoMatchForCriteria />
          )}
          {!sortedGroups.length && search.length > 0 && <NoMatchForSearch />}
          {!!sortedGroups.length && (
            <div>
              {sortedGroups.map((g) => (
                <GroupModeSection
                  key={`${g.field}-${g.key}`}
                  value={g.key}
                  field={g.field}
                  earnings={g.summary}
                  sorter={sorter}
                  salesFilter={salesFilter}
                  spaceId={spaceId}
                  currency={currency}
                  columns={visibleColumns}
                  tz={tz}
                />
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

const LIST_MODE_LIMIT = 300;
const ListMode = ({
  spaceId,
  salesFilter,
  currency,
  selectedPreset,
  search,
  visibleColumns,
  sorter,
  grouper,
  groupsCollapsed,
  setGroupCollapsed
}: {
  spaceId: string;
  salesFilter: SalesFilterArgs;
  currency: CurrencyCode;
  selectedPreset?: IPreset;
  search: string;
  visibleColumns: Set<ColumnName>;
  sorter: SalesSorter;
  grouper: SalesGrouper | null;
  groupsCollapsed?: {
    defaultValue: boolean;
    byKey: { [key: string]: boolean };
  };
  setGroupCollapsed?: (key: string, collapsed: boolean) => void;
}) => {
  // don't use as query param anymore, so that it's harder
  // to end up out of bounds
  const [page, setPage] = useState(1);

  const args = useMemo(() => {
    const a: TransactionsArgs = { ...salesFilter };
    // only date and amount sorters are supported right now!
    if (sorter.key === 'date') {
      a.orderBy = {
        col: 'sale_date',
        dir: sorter.items.dir === 'desc' ? 'DESC' : 'ASC'
      };
    }
    if (sorter.key === 'amount') {
      a.orderBy = {
        col: 'commission',
        dir: sorter.items.dir === 'desc' ? 'DESC' : 'ASC'
      };
    }
    a.page = page;
    a.limit = LIST_MODE_LIMIT;
    return a;
  }, [salesFilter, sorter, page]);
  const [data, loading, error] = useSalesConverted(spaceId, args, currency);
  useErrorLoggerWithLabel('transaction list', error);

  return (
    <>
      {!data && loading && <Loader height={300} />}
      {data && (
        <>
          {!data.length &&
            selectedPreset &&
            search.length === 0 &&
            selectedPreset.emptyState}
          {!data.length && search.length === 0 && <NoMatchForCriteria />}
          {!data.length && search.length > 0 && <NoMatchForSearch />}
          <SalesList
            visibleColumns={visibleColumns}
            search={search}
            sales={data}
            page={page}
            setPage={setPage}
            pageSize={LIST_MODE_LIMIT}
            paginateExternally
            grouper={grouper}
            products={EMPTY_ARR}
            sorter={sorter}
            currency={currency}
            groupsCollapsed={groupsCollapsed}
            setGroupCollapsed={setGroupCollapsed}
          />
        </>
      )}
    </>
  );
};

const SalesBody = React.memo(
  ({
    currency,
    timeframeStart,
    timeframeEnd,
    timeframeTz
  }: {
    currency: CurrencyCode;
    timeframeStart: string;
    timeframeEnd: string;
    timeframeTz: string;
  }) => {
    const { changeQuery } = useRoutes();
    const { space, tz } = useCurrentUser();
    const [search, setSearch] = useStringQueryParam('q');
    const [groupBy] = useQueryParam('group', queryParamToGroup, (g) =>
      g === null ? 'none' : g.key
    );
    const {
      dialogOpen: exportDialogOpen,
      openDialog: setExportDialogOpen,
      closeDialog: onCloseExportDialog
    } = useDialogState();

    const [sortBy, setSortBy] = useQueryParam(
      'sort',
      (p) => (p ? SORTERS[p] || null : null),
      (s) => (s ? s.key : undefined)
    );

    const allSites = useMemo(() => new Set(getActiveDomainUrls(space, true)), [
      space
    ]);
    const [sites, setSites] = useQueryParam(
      'site',
      (p) =>
        new Set(
          p
            ? queryParamToList(p)
            : [...getActiveDomainUrls(space, true), UNATTRIBUTED]
        ),
      (ss) =>
        ss.size === getActiveDomainUrls(space, true).length + 1 // including UNATTRIBUTED
          ? undefined
          : setToQueryParam(ss)
    );

    const [
      selectedStatuses,
      setSelectedStatuses,
      toStatusQueryParam
    ] = useQueryParam(
      'status',
      (p) => new Set(p ? queryParamToList<SaleStatus>(p) : SALE_STATUSES),
      (ss) =>
        ss.size === SALE_STATUSES.length ? undefined : setToQueryParam(ss)
    );

    const [selectedTypes, setSelectedTypes, toTypeQueryParam] = useQueryParam(
      'sale_type',
      (p) =>
        new Set(p ? queryParamToList<SaleType>(p) : SALE_TYPES_WITHOUT_BONUS),
      (ss) =>
        ss.size === SALE_TYPES_WITHOUT_BONUS.length
          ? undefined
          : setToQueryParam(ss)
    );

    const [
      selectedPayoutStatuses,
      setSelectedPayoutStatuses,
      toPayoutStatusQueryParam
    ] = useQueryParam(
      'payout_status',
      (p) => new Set(p ? queryParamToList<PayoutStatus>(p) : PAYOUT_STATUSES),
      (ss) =>
        ss.size === PAYOUT_STATUSES.length ? undefined : setToQueryParam(ss)
    );

    const [visibleColumns, setVisibleColumns] = useQueryParam(
      'columns',
      (p) =>
        new Set(p ? queryParamToList<ColumnName>(p) : DEFAULT_VISIBLE_COLUMNS),
      (cs) =>
        isSameColumns([...cs], DEFAULT_VISIBLE_COLUMNS)
          ? undefined
          : setToQueryParam(cs)
    );

    const [
      selectedPartners,
      setSelectedPartners,
      toPartnerQueryParam
    ] = useNullableStringSetQueryParam('partners');

    const [
      selectedAdvertisers,
      setSelectedAdvertisers,
      toAdvertiserQueryParam
    ] = useNullableStringSetQueryParam('advertisers', '---');
    const [
      selectedPayoutIds,
      setSelectedPayoutIds,
      toPayoutIdQueryParam
    ] = useNullableStringSetQueryParam('payout_ids', '---');

    const [graphMode, setGraphMode] = useTypedStringQueryParam<GraphMode>(
      'graph_mode',
      'platform'
    );

    const [chartMode, setChartMode] = useTypedStringQueryParam<ChartMode>(
      'chart_mode',
      'barChart'
    );

    const isPresetSelected = (p: IPreset) => {
      if (p.groupBy !== groupBy) {
        return false;
      }
      if (p.sorter !== sortBy) {
        return false;
      }

      const statArr = Array.from(p.statuses);
      const selectedStatusArr = Array.from(selectedStatuses);
      const sharedStatuses = intersection(statArr, selectedStatusArr);

      if (
        sharedStatuses.length !== statArr.length ||
        statArr.length !== selectedStatusArr.length
      ) {
        return false;
      }

      const colArr = Array.from(p.columns);
      const selectedColArr = Array.from(visibleColumns);
      const sharedColumns = intersection(colArr, selectedColArr);

      if (
        sharedColumns.length !== colArr.length ||
        colArr.length !== selectedColArr.length
      ) {
        return false;
      }

      return true;
    };

    const selectedPreset = useMemo(() => {
      return PRESETS.find(isPresetSelected);
      // eslint-disable-next-line
    }, [
      selectedStatuses,
      selectedPartners,
      selectedTypes,
      sites,
      visibleColumns,
      groupBy,
      sortBy
    ]);

    const [groupsCollapsed, setGroupsCollapsed] = useState<{
      defaultValue: boolean;
      byKey: { [key: string]: boolean };
    }>(
      selectedPreset?.collapsed ? ALL_COLLAPSED : ALL_EXPANDED_BESIDES_UNKNOWN
    );

    const setGroupCollapsed = useCallback(
      (key: string, collapsed: boolean) =>
        setGroupsCollapsed((s) => ({
          ...s,
          byKey: {
            ...s.byKey,
            [key]: collapsed
          }
        })),
      []
    );

    const timeframe = useMemo(
      () => ({ start: timeframeStart, end: timeframeEnd, tz: timeframeTz }),
      [timeframeStart, timeframeEnd, timeframeTz]
    );

    useWhatChanged(
      {
        timeframe,
        selectedStatuses,
        selectedPartners,
        sites,
        allSites
      },
      'salesFilterArgs',
      { disable: !LOG }
    );

    const salesFilter: SalesFilterArgs = useMemo(() => {
      // TODO for statuses, sites and partners
      // check if ALL are selected - then discard the filter altogeher
      // TODO allow to pass null for certain values as filter
      // e.g. show me only unattributed sales
      const filters: SalesFilterArgs = { dates: timeframeToMs(timeframe) };
      if (selectedStatuses.size !== SALE_STATUSES.length) {
        filters.sale_status = [...selectedStatuses];
      }
      if (selectedTypes.size !== SALE_TYPES.length) {
        filters.sale_type = [...selectedTypes];
      }
      // + 1 to account for unattributed
      if (allSites.size + 1 !== sites.size) {
        filters.origin = [...sites];
      }
      if (selectedPartners && selectedPartners.size) {
        filters.partner_key = [...selectedPartners];
      }
      if (selectedAdvertisers && selectedAdvertisers.size) {
        filters.advertiser_name = [...selectedAdvertisers];
      }
      if (selectedPayoutIds && selectedPayoutIds.size) {
        filters.payout_id = [...selectedPayoutIds];
      }
      if (
        selectedPayoutStatuses &&
        selectedPayoutStatuses.size !== PAYOUT_STATUSES.length
      ) {
        filters.payout_status = [...selectedPayoutStatuses];
      }
      if (search) {
        filters.search = { q: search };
      }

      return filters;
    }, [
      search,
      timeframe,
      selectedStatuses,
      selectedTypes,
      selectedPartners,
      selectedAdvertisers,
      selectedPayoutStatuses,
      selectedPayoutIds,
      sites,
      allSites
    ]);

    const [partners] = useKnownPartners(space.id, salesFilter);
    const [advertisers] = useKnownAdvertisers(space.id, salesFilter);
    const [payoutIds] = useKnownPayoutIds(space.id, salesFilter);

    const [totals, loadingTotals] = useTotals(
      space.id,
      salesFilter,
      currency,
      EARNING_MINIMAL_FIELD_SET.COMMISSIONS_VOLUME_AND_TX_COUNT
    );

    // for data loading:
    // - return value should be:
    //   - A group, sorted by whatever is requested
    //   - Group can come with transactions associated with it already. If there are any, expand the group
    //
    // - For all groupings besides dates, only the group headers will be retrieved - load rows afterwards on demand
    // - For the date grouping just retrieve transactions and group on the client

    const activeSorter =
      sortBy || (groupBy ? groupBy.defaultSorter : SORTERS.date);

    // const [groupedEarnings] = useGroupedEarnings(
    //   space.id,
    //   groupBy?.key || 'date',
    //   salesFilter,
    //   currency
    // );

    // TODO
    // - Allow to run in two modes:
    //   - when grouped by date and sorted by date OR grouped by nothing
    //     - Use the old approach of requesting transactions and passing them to the normal SalesList component
    //     - let's call this mode LIST
    //   - for everything else
    //     - different code path: get grouped earnings ONLY. Display the groups, all collapsed.
    //     - When a group is expanded, make a query for the transactions inside of the group, with the appropriate sort settings etc
    //     - let's call this mode GROUP

    const mode: 'LIST' | 'GROUP' =
      !groupBy || (groupBy.key === 'date' && activeSorter.key === 'date')
        ? 'LIST'
        : 'GROUP';

    return (
      <>
        <PageToolbar sticky offset={DEFAULT_OFFSET}>
          <PageToolbarSection flex={8}>
            <SiteSelector
              sites={getActiveDomainUrls(space, true)}
              value={sites}
              onChange={setSites}
              includeUnattributed
            />
            <SortSelector
              value={sortBy}
              onChange={setSortBy}
              defaultValue={groupBy ? groupBy.defaultSorter : SORTERS.date}
              sorters={SORTERS}
            />
            <SalesFilter
              partners={{
                partners: partners || EMPTY_ARR,
                value: selectedPartners,
                onChange: setSelectedPartners
              }}
              payoutIds={{
                payoutIds: payoutIds || EMPTY_ARR,
                value: selectedPayoutIds,
                onChange: setSelectedPayoutIds
              }}
              payoutStatuses={{
                value: selectedPayoutStatuses,
                onChange: setSelectedPayoutStatuses
              }}
              advertisers={{
                advertisers: advertisers || EMPTY_ARR,
                value: selectedAdvertisers,
                onChange: setSelectedAdvertisers
              }}
              statuses={{
                value: selectedStatuses,
                onChange: setSelectedStatuses
              }}
              types={{
                value: selectedTypes,
                onChange: setSelectedTypes
              }}
              onReset={() =>
                changeQuery({
                  ...toStatusQueryParam(new Set(SALE_STATUSES)),
                  ...toTypeQueryParam(new Set(SALE_TYPES_WITHOUT_BONUS)),
                  ...toPartnerQueryParam(null),
                  ...toAdvertiserQueryParam(null),
                  ...toPayoutIdQueryParam(null),
                  ...toPayoutStatusQueryParam(new Set(PAYOUT_STATUSES))
                })
              }
            />
            <ColumnSelector
              value={visibleColumns}
              onChange={setVisibleColumns}
              columns={COLUMNS}
              short
            />
            <ExpandOrCollapse
              allCollapsed={isEqual(ALL_COLLAPSED, groupsCollapsed)}
              onChange={setGroupsCollapsed}
            />
          </PageToolbarSection>
          <PageToolbarSection flex={2} justifyContent="center">
            <SearchInput
              value={search}
              onChange={setSearch}
              placeholder="Search transactions"
              size="small"
            />
          </PageToolbarSection>
          <PageToolbarSection flex={1} justifyContent="flex-end">
            <TimeframePickerStandalone />
            <IconButton
              onClick={() => {
                setExportDialogOpen();
              }}
            >
              <DownloadCloud size={16} />
            </IconButton>
          </PageToolbarSection>
        </PageToolbar>

        <Grid>
          <ChartCard
            noMaximize
            centered
            heading={`Total earnings`}
            subheading={<TimeframeDurationChip tf={timeframe} />}
            size="small"
            padding="dense"
            topRight={
              totals && loadingTotals ? <InlineLoader color="inherit" /> : null
            }
          >
            <TotalsCard totals={totals} loading={loadingTotals} />
          </ChartCard>
          <EarningsBarChartCard
            spaceId={space.id}
            q={salesFilter}
            currency={currency}
            graphMode={graphMode}
            setGraphMode={setGraphMode}
            chartMode={chartMode}
            setChartMode={setChartMode}
          />
        </Grid>

        {mode === 'LIST' && (
          <ListMode
            spaceId={space.id}
            selectedPreset={selectedPreset}
            salesFilter={salesFilter}
            visibleColumns={visibleColumns}
            search={search}
            grouper={groupBy}
            sorter={activeSorter}
            currency={currency}
            groupsCollapsed={groupsCollapsed}
            setGroupCollapsed={setGroupCollapsed}
          />
        )}
        {mode === 'GROUP' && (
          <GroupMode
            spaceId={space.id}
            selectedPreset={selectedPreset}
            salesFilter={salesFilter}
            tz={tz}
            visibleColumns={visibleColumns}
            search={search}
            grouper={groupBy}
            sorter={activeSorter}
            currency={currency}
            groupsCollapsed={groupsCollapsed}
            setGroupCollapsed={setGroupCollapsed}
          />
        )}
        <TransactionExportDialog
          isOpen={exportDialogOpen}
          onClose={onCloseExportDialog}
          onExport={() =>
            callFirebaseFunction(CF.sales.exportSales, {
              spaceId: space.id,
              q: salesFilter
            })
          }
        />
      </>
    );
  },
  debuggedShallowEqual('SalesBody')
);

const LOG = false;

export const PagePerformanceNewTransactionsPg = () => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const [timeframe] = useLegacyTimeframe();
  const currency = useSpaceCurrency();
  const { space } = useCurrentUser();
  const [hasAnyReports] = useHasAnyReports(space.id);

  const body = () => {
    // if (loadingHasAnyReports) {
    //   return <Loader height={SALES_LOADER_HEIGHT} />;
    // }

    if (hasAnyReports === false) {
      // as opposed to just void and waiting for it
      return <EmptyState onImport={openDialog} />;
    }

    return (
      <Section>
        <SalesBody
          currency={currency}
          timeframeStart={timeframe.start}
          timeframeEnd={timeframe.end}
          timeframeTz={timeframe.tz}
        />
      </Section>
    );
  };

  return (
    <>
      <PerformancePageBody noTopPadding>{body()}</PerformancePageBody>
      <ImportDialog
        open={dialogOpen}
        onClose={closeDialog}
        currency={currency}
      />
    </>
  );
};
