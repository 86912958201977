import { ISpace } from '../../../../domainTypes/space';
import { styled } from '../../../../emotion';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Section } from '../../../../layout/Section';
import { Card, CardContent, Tooltip, Typography } from '@material-ui/core';
import { Plan, PlanFrequency } from '../../../../domainTypes/billing';
import moment from 'moment-timezone';
import { CanvasBar } from '../../../../layout/Canvas';
import { callFirebaseFunction } from '../../../../services/firebaseFunctions';
import Stripe from 'stripe';
import {
  ANALYTICS_OPTIONS,
  FORMATTERS,
  ITimeBasedCounter,
  OPTIONS,
  TrafficBiaxialChart
} from '../../../../components/Charts/TrafficBiaxialChart';
import {
  useColorsSpectrumPrimary,
  useColorsSpectrumSuccess
} from '../../../../services/color';
import { COLORS } from '../../../../domainTypes/colors';
import { AlertCircle } from 'react-feather';
import { usePlans } from '../../../Billing/service';
import { Currency, formatCurrency } from '../../../../components/Number';
import { timeframeToIsoRange } from '../../../../services/time';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { lastDays, multiplyTimeframe } from '../../../../services/analytics';
import { LoadingValue, useMappedLoadingValue } from '../../../../services/db';
import { Loader } from '../../../../components/Loader';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';
import { SubscriptionDetails } from '../../../Billing/components/SubscriptionDetails';
import { Invoices } from '../../../Billing/components/Invoices';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import {
  AnalyticsQuery,
  ISOTimeRange
} from '../../../../domainTypes/analytics_v2';
import { TIMEKEY_FORMAT } from '../../../../domainTypes/analytics';

type SpaceProps = {
  space: ISpace;
};

const SubscriptionDetailsContainer = styled('div')`
  border-top: ${(p) => p.theme.custom.border.standard};
  padding-top: ${(p) => p.theme.spacing(3)}px;
`;

const getBillingPortalSession = ({
  spaceId,
  flow,
  interval
}: {
  spaceId: string;
  flow?: 'subscription_update_confirm';
  interval?: 'monthly' | 'yearly';
}) => {
  return callFirebaseFunction<{ session: Stripe.BillingPortal.Session }>(
    'billing-createCustomerPortalSession',
    {
      spaceId,
      flow,
      interval
    }
  );
};

const BillingTrafficChart: React.FC<{
  timeseries: LoadingValue<ITimeBasedCounter[]>;
}> = ({ timeseries }) => {
  const [data, loading] = timeseries;
  const [yAxisLeft] = useState(OPTIONS[0].value);
  const [yAxisRight] = useState(OPTIONS[2].value);
  const [primaryColor] = useColorsSpectrumPrimary();
  const [secondaryColor] = useColorsSpectrumSuccess();

  return (
    <TrafficBiaxialChart
      yAxisRight={yAxisRight}
      yAxisLeft={yAxisLeft}
      loading={loading}
      counters={data}
      margin="normal"
      compare={false}
      animate={true}
      colors={[primaryColor, secondaryColor]}
      options={ANALYTICS_OPTIONS}
      formatters={FORMATTERS.TIME_KEY}
    />
  );
};

const Table = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: ${(p) => p.theme.spacing(3)}px;
  padding: ${(p) => p.theme.spacing(2)}px ${(p) => p.theme.spacing(4)}px;
`;

const LimitExceededIcon = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: ${COLORS.gold.gold4};
  color: ${COLORS.gold.gold10};
`;

const LimitExceeded = ({ title }: { title: string }) => {
  return (
    <Tooltip placement="top" title={title}>
      <LimitExceededIcon>
        <AlertCircle size={15} />
      </LimitExceededIcon>
    </Tooltip>
  );
};

const ResourceUsageTable = ({
  space,
  earnings,
  pageViews,
  billingFrequency
}: {
  space: ISpace;
  earnings: LoadingValue<number>;
  pageViews: LoadingValue<{ prev: number; curr: number }>;
  billingFrequency: PlanFrequency;
}) => {
  const plans = usePlans();
  const activePlan = plans.find((p) => p.name === space.billing.activePlan);
  const activeSites = space.domains.filter((d) => d.active).length;

  if (!activePlan) {
    return <div>No active plan found</div>;
  }

  const [earningsData, loadingEarnings] = earnings;
  const [trafficData, loadingTraffic] = pageViews;

  return (
    <div>
      <Table>
        <div>
          <Typography
            variant="body1"
            component="h2"
            style={{ fontWeight: 'bold' }}
          >
            Limits
          </Typography>
        </div>
        <div>
          <Typography
            variant="body1"
            component="h2"
            style={{ fontWeight: 'bold' }}
          >
            Usage
          </Typography>
        </div>
        <div>
          <Typography variant="body2" component="p">
            Tracked revenue (Last{' '}
            {billingFrequency === 'm' ? '30 days' : '365 days'})
          </Typography>
        </div>
        <div>
          <Typography variant="body2" component="p">
            {loadingEarnings || !earningsData ? (
              <span>Loading...</span>
            ) : (
              <Typography
                variant="body2"
                component="p"
                style={{ display: 'flex', gap: '6px', alignItems: 'center' }}
              >
                <Currency cents={earningsData} currency="USD" /> of{' '}
                <Currency
                  cents={
                    activePlan.revenue.end *
                    100 *
                    (billingFrequency === 'y' ? 12 : 1)
                  }
                  currency="USD"
                />
                {earningsData >
                  activePlan.revenue.end *
                    100 *
                    (billingFrequency === 'y' ? 12 : 1) && (
                  <LimitExceeded
                    title={`
                  You've exceeded your plan's revenue limit of ${formatCurrency(
                    activePlan.revenue.end *
                      100 *
                      (billingFrequency === 'y' ? 12 : 1),
                    'USD'
                  )} USD for the ${
                      billingFrequency === 'y' ? 'year' : 'month'
                    }. Please upgrade now to keep tracking.
                `}
                  />
                )}
              </Typography>
            )}
          </Typography>
        </div>
        <div>
          <Typography variant="body2" component="p">
            Pageviews
          </Typography>
        </div>
        <div>
          <Typography
            variant="body2"
            component="p"
            style={{ display: 'flex', gap: '6px', alignItems: 'center' }}
          >
            {loadingTraffic || !trafficData ? (
              <span>Loading...</span>
            ) : (
              <span>{trafficData.curr.toLocaleString('en-US')}</span>
            )}{' '}
            of {activePlan.pageviews.toLocaleString('en-US')}{' '}
            {trafficData &&
              trafficData.curr > activePlan.pageviews &&
              trafficData.prev > activePlan.pageviews && (
                <LimitExceeded
                  title={`Last month you also tracked ${trafficData.prev.toLocaleString(
                    'en-US'
                  )} pageviews. This means you've exceeded your plan's pageview limit for two months in a row. Upgrade now to continue tracking.`}
                />
              )}
            {trafficData &&
              trafficData.curr > activePlan.pageviews &&
              trafficData.prev <= activePlan.pageviews && (
                <LimitExceeded
                  title={`You've exceeded your pageview limit for the last 30 days. Please upgrade your plan.`}
                />
              )}
          </Typography>
        </div>
        <div>
          <Typography variant="body2" component="p">
            Websites
          </Typography>
        </div>
        <div>
          <Typography
            variant="body2"
            component="p"
            style={{ display: 'flex', gap: '6px', alignItems: 'center' }}
          >
            {activeSites} of {activePlan.sites}{' '}
            {activeSites > activePlan.sites && (
              <LimitExceeded title="You've added more sites than allowed by your plan. Please upgrade or deactivate the other sites." />
            )}
          </Typography>
        </div>
      </Table>
    </div>
  );
};

const toPlanName = (plan: Plan) => {
  if (plan.includes('PORTFOLIO')) {
    return 'Portfolio';
  }
  if (plan.includes('MEDIA_COMPANY')) {
    return 'Media Company';
  }
  if (plan.includes('SOLO_CREATOR')) {
    return 'Solo Creator';
  }
  if (plan.includes('CUSTOM')) {
    return 'Enterprise';
  }
  if (plan.includes('100K')) {
    return '100K Legacy';
  }
  if (plan.includes('250K')) {
    return '250K Legacy';
  }
  if (plan.includes('500K')) {
    return '500K Legacy';
  }
  if (plan.includes('750K')) {
    return '750K Legacy';
  }
  if (plan.includes('1M')) {
    return '1M Legacy';
  }
  if (plan.includes('2M')) {
    return '2M Legacy';
  }
  return 'Legacy';
};

const useEarnings = (frequency: PlanFrequency) => {
  const { space } = useCurrentUser();
  const today = useCallback(() => moment().tz(space.config.tz).startOf('day'), [
    space.config.tz
  ]);

  const query = useMemo<AnalyticsQuery>(() => {
    const range: ISOTimeRange = {
      end: today().toISOString(),
      start:
        frequency === 'm'
          ? today().subtract(1, 'month').toISOString()
          : today().subtract(1, 'year').toISOString()
    };
    return {
      range,
      select: ['commission_sum_net']
    };
  }, [frequency, today]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (result) => result.rows[0].data.commission_sum_net?.curr ?? 0
  );
};

const useTraffic = () => {
  const { space } = useCurrentUser();

  const query = useMemo<AnalyticsQuery>(() => {
    const timeframe = lastDays(30);
    const timeframeToCompare = multiplyTimeframe(timeframe, 2);
    return {
      range: timeframeToIsoRange(timeframe),
      compare: {
        range: timeframeToIsoRange(timeframeToCompare)
      },
      select: ['c', 'p', 'v', 's'],
      interval: {
        unit: 'day',
        value: 1,
        tz: space.config.tz
      },
      orderBy: [
        {
          field: 'interval',
          direction: 'ASC'
        }
      ]
    };
  }, [space.config.tz]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (result) => {
      return {
        currentTimeseries: result.rows.map((r) => ({
          pageViews: r.data.p?.curr ?? 0,
          served: r.data.s?.curr ?? 0,
          viewed: r.data.v?.curr ?? 0,
          clicked: r.data.c?.curr ?? 0,
          ts: moment(r.group.interval).format(TIMEKEY_FORMAT)
        })),
        pageViews: {
          curr: result.rows.reduce((m, r) => m + (r.data.p?.curr ?? 0), 0),
          prev: result.rows.reduce((m, r) => m + (r.data.p?.prev ?? 0), 0)
        }
      };
    }
  );
};

export const Subscription = ({ space }: SpaceProps) => {
  const plans = usePlans();
  const activePlanIndex = plans.findIndex(
    (p) => p.name === space.billing.activePlan
  );
  const activePlan = plans[activePlanIndex];
  const [canEditBilling] = useHasCurrentUserRequiredScopes(['billing.edit']);
  const currentInterval = activePlan.frequency;

  // If we load this part of the page and the URL
  // includes a flow parameter, automatically open
  // that flow in the same window
  const url = new URL(window.location.href);
  const flow = url.searchParams.get('flow');
  const interval =
    (url.searchParams.get('interval') as 'monthly' | 'yearly' | undefined) ||
    undefined;

  useEffect(() => {
    if (flow === 'subscription_update_confirm') {
      getBillingPortalSession({
        spaceId: space.id,
        flow,
        interval
      }).then((r) => {
        const { session } = r;
        url.searchParams.delete('flow');
        url.searchParams.delete('interval');
        window.location.replace(session.url);
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const earnings = useEarnings(currentInterval);
  const traffic = useTraffic();
  const pageViews = useMappedLoadingValue(traffic, (d) => d.pageViews);
  const timeseries = useMappedLoadingValue(traffic, (d) => d.currentTimeseries);

  if (flow) {
    return (
      <div>
        <Card
          style={{
            textAlign: 'center',
            paddingTop: '150px',
            paddingBottom: '150px'
          }}
        >
          <Loader size={36} />
          <br />
          <Typography variant="body2" component="p">
            Opening your customer portal...
          </Typography>
        </Card>
      </div>
    );
  }

  return (
    <>
      <Section>
        <Card>
          <CardContent>
            <Typography
              variant="h6"
              component="h1"
              paragraph
              style={{ textAlign: 'center', fontWeight: 'bold' }}
            >
              {toPlanName(activePlan.name)} Plan
            </Typography>
            {activePlan && activePlan.pageviews > 0 && (
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ textAlign: 'center', marginBottom: '24px' }}
              >
                {canEditBilling && (
                  <ButtonWithPromise
                    variant="text"
                    color="primary"
                    pending="Opening..."
                    style={{ marginTop: '12px' }}
                    onClick={async () => {
                      const { session } = await getBillingPortalSession({
                        spaceId: space.id
                      });
                      window.open(session.url);
                    }}
                  >
                    Change plan or billing details
                  </ButtonWithPromise>
                )}
              </Typography>
            )}
            <SubscriptionDetailsContainer>
              <SubscriptionDetails space={space} />
            </SubscriptionDetailsContainer>
          </CardContent>
        </Card>
      </Section>
      <Section>
        <CanvasBar style={{ alignItems: 'center' }}>
          <div>Resource usage (Last 30 Days)</div>
        </CanvasBar>
        {activePlan && !activePlan.name.includes('CUSTOM') && (
          <Card style={{ paddingTop: '24px' }}>
            <ResourceUsageTable
              space={space}
              earnings={earnings}
              pageViews={pageViews}
              billingFrequency={activePlan.frequency}
            />
          </Card>
        )}
        <Card style={{ marginTop: '24px', paddingTop: '24px' }}>
          <BillingTrafficChart timeseries={timeseries} />
        </Card>
      </Section>
      <Section>
        <CanvasBar>Invoices</CanvasBar>
        <Invoices space={space} />
      </Section>
      {canEditBilling && (
        <Section>
          <CanvasBar>Manage Subscription</CanvasBar>
          <Card>
            <CardContent>
              <Typography variant="body2" component="p">
                Affilimate parters with Stripe for secure billing. Inside the
                Subscription Center, you can update your billing details, tax
                ID, payment method, and invoice details as well as modify or
                cancel a subscription.
              </Typography>
              <ButtonWithPromise
                variant="contained"
                color="primary"
                pending="Opening..."
                style={{ marginBottom: '24px', marginTop: '24px' }}
                onClick={async () => {
                  const { session } = await getBillingPortalSession({
                    spaceId: space.id
                  });
                  window.open(session.url);
                }}
              >
                Open Subscription Center
              </ButtonWithPromise>
              <Typography
                color="textSecondary"
                variant="caption"
                component="p"
                paragraph
              >
                <strong>Questions about your plan or billing?</strong> Write us
                anytime, through our Support icon in the bottom right corner.
              </Typography>
            </CardContent>
          </Card>
        </Section>
      )}
    </>
  );
};
