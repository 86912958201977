import queryString from 'query-string';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { PATHS, Routes } from './domainTypes/routes';
import { PageAuditDetails } from './features/Audit/pages/Details';
import { PageAuditOverview } from './features/Audit/pages/Overview';
import { CampaignCreator } from './features/Campaigns/pages/CampaignCreator';
import { CampaignDetails } from './features/Campaigns/pages/CampaignDetails';
import { CampaignReport } from './features/Campaigns/pages/CampaignReport';
import { CampaignsActive } from './features/Campaigns/pages/CampaignsActive';
import { CampaignsComplete } from './features/Campaigns/pages/CampaignsComplete';
import { PageCampaignsPerformance } from './features/Campaigns/pages/CampaignsPerformance';
import { PageCampaignsSettings } from './features/Campaigns/pages/CampaignsSettings';
import { CampaignsTeams } from './features/Campaigns/pages/CampaignsTeams';
import { PageCustomDimensionsDetails } from './features/Content/pages/CustomDimensions/Details';
import { PageCustomDimensionsManager } from './features/Content/pages/CustomDimensions/Manager';
import { PageCustomDimensionsSlot } from './features/Content/pages/CustomDimensions/Slots';
import { PageContentDetailsAudience } from './features/Content/pages/DetailsAudience';
import { PageContentDetailsHeatmap } from './features/Content/pages/DetailsHeatmap';
import { PageContentDetailsLinks } from './features/Content/pages/DetailsLinks';
import { PageContentDetailsRevisions } from './features/Content/pages/DetailsRevisions';
import { PageContentDetailsTrends } from './features/Content/pages/DetailsTrends';
import { PageContentOverview } from './features/Content/pages/Overview';
import { PageContentOverviewV2 } from './features/Content/pages/Overview_v2';
import { PageContentTagsManager } from './features/Content/pages/Tags/Manager/Manager';
import { PageContentAuthorsOverview } from './features/Content/pages/Tags/Reports/Authors';
import { PageContentTagGroupOverview } from './features/Content/pages/Tags/Reports/GroupOverview';
import { ReferrersOverview } from './features/Content/pages/Traffic/Referrers';
import { ReferrerGroupDetails } from './features/Content/pages/Traffic/Referrers/GroupDetails';
import { UtmParameterDetails } from './features/Content/pages/Traffic/Utm';
import { PageDashboardOverviewV2 } from './features/Dashboard/pages/OverviewV2';
import { PageDashboardRealtime } from './features/Dashboard/pages/Realtime';
import { PageApiDocs } from './features/Docs/pages/Api';
import { PageDocsKnowledgeBase } from './features/Docs/pages/KnowledgeBase';
import { PageInventoryIssues } from './features/Inventory/pages/Issues';
import { PageLinkDetailsAudience } from './features/Links/pages/DetailsAudience';
import { PageLinkDetailsOverview } from './features/Links/pages/DetailsOverview';
import { PageLinkDetailsPages } from './features/Links/pages/DetailsPages';
import { PageLinksInventory } from './features/Links/pages/Inventory';
import { PageAmazonIssuesReport } from './features/Links/pages/Overview/AmazonIssuesReport';
import { PageLinksOverviewV2 } from './features/Links/pages/OverviewV2';
import { PageLinksScanDetails } from './features/Links/pages/ScanDetails';
import { PageLinksScanHistory } from './features/Links/pages/ScanHistory';
import { PageLinksSettings } from './features/Links/pages/Settings';
import { PageLinksAmazonSettings } from './features/Links/pages/Settings/AmazonSettings';
import { PagePerformanceImportActivity } from './features/PerformanceNew/pages/Activity';
import { PagePerformanceAdvertisers } from './features/PerformanceNew/pages/Advertisers';
import { PagePerformanceAdvertiserDetails } from './features/PerformanceNew/pages/Advertisers/pages/AdvertiserDetails';
import { PagePerformanceChannels } from './features/PerformanceNew/pages/Channels';
import { PagePerformanceNewIntegrations } from './features/PerformanceNew/pages/Integrations';
import { PagePerformanceNetworks } from './features/PerformanceNew/pages/Networks';
import { PagePerformancePayouts } from './features/PerformanceNew/pages/Payouts';
import { PagePerformancePayoutDetails } from './features/PerformanceNew/pages/Payouts/pages/PayoutDetails';
import { PagePerformanceRates } from './features/PerformanceNew/pages/Rates';
import { PagePerformanceRulesV3 } from './features/PerformanceNew/pages/RulesV3';
import { PagePerformanceNewTransactions } from './features/PerformanceNew/pages/Transactions';
import { PageRecommendations } from './features/Recommendations/pages/Recommendations';
import { PageRevisionsOverview } from './features/Revisions/pages/Overview';
import { PagesSettingsNewAffiliateProgram } from './features/Settings/pages/AffiliateProgram';
import { PageSettingsApi } from './features/Settings/pages/Api';
import { PagesSettingsNewBilling } from './features/Settings/pages/Billing';
import { PagesSettingsNewBrowserExtension } from './features/Settings/pages/BrowserExtension';
import { PageSettingsContent } from './features/Settings/pages/Content';
import { PageSettingsExports } from './features/Settings/pages/Exports';
import { PageSettingsNewGeneral } from './features/Settings/pages/General';
import { PagesSettingsNewNotifications } from './features/Settings/pages/Notifications';
import { PagesSettingsNewProfile } from './features/Settings/pages/Profile';
import { PageSettingsTracking } from './features/Settings/pages/Tracking';
import { PageSettingsNewUserManagement } from './features/Settings/pages/UserManagement';
import { PageStaff } from './features/Staff';
import { PageToolsLinkGenerator } from './features/Tools/pages/LinkGenerator';
import { Centered } from './layout/Centered';
import { isValidUrl, removeTrailingSlash } from './services/url';

export interface IPage<T = any> {
  path: string;
  redirectFrom?: string[];
  redirect?: (props: RouteComponentProps<T>, routes: Routes) => string;
  render?: (props: RouteComponentProps<T>) => React.ReactNode;
}

const getQueryParam = <T extends {}>(
  search: string,
  key: string,
  defaultValue: T
) => {
  const query = queryString.parse(search);
  if (!query) {
    return defaultValue;
  }
  const value = query[key];
  if (value === undefined || value === null) {
    return defaultValue;
  }
  return (value as unknown) as T;
};

const withUrl = (
  fn: (url: string) => React.ReactNode
): ((props: RouteComponentProps<{ url: string }>) => React.ReactNode) => {
  return (props) => {
    const url = new URLSearchParams(props.location.search).get('url');

    if (url === null || !isValidUrl(url)) {
      return <Centered height={500}>Invalid URL.</Centered>;
    }

    return fn(removeTrailingSlash(url));
  };
};

export const PAGES: IPage[] = [
  {
    path: PATHS.content.details.trends,
    render: withUrl((url) => <PageContentDetailsTrends url={url} />)
  },
  {
    path: PATHS.content.details.heatmap,
    render: withUrl((url) => <PageContentDetailsHeatmap url={url} />)
  },
  {
    path: PATHS.content.details.links,
    render: withUrl((url) => <PageContentDetailsLinks url={url} />)
  },
  {
    path: PATHS.content.details.revisions,
    render: withUrl((url) => <PageContentDetailsRevisions url={url} />)
  },
  {
    path: PATHS.content.details.audience,
    render: withUrl((url) => <PageContentDetailsAudience url={url} />)
  },
  {
    path: PATHS.content.overview,
    render: () => <PageContentOverview />
  },
  {
    path: PATHS.content.overview_v2,
    render: () => <PageContentOverviewV2 />
  },
  {
    path: PATHS.content.tags.manage,
    render: () => <PageContentTagsManager />
  },
  {
    path: PATHS.content.tags.group,
    render: (p) => (
      <PageContentTagGroupOverview groupId={p.match.params.groupId} />
    )
  },
  {
    path: PATHS.content.tags.authors,
    render: () => <PageContentAuthorsOverview />
  },
  {
    path: PATHS.content.referrers.overview,
    render: () => <ReferrersOverview />
  },
  {
    path: PATHS.content.referrers.groupDetails,
    render: (p) => <ReferrerGroupDetails groupId={p.match.params.groupId} />
  },
  {
    path: PATHS.content.utm.details,
    render: (p) => <UtmParameterDetails parameter={p.match.params.parameter} />
  },
  {
    path: PATHS.content.customDimensions.slot,
    render: (p) => <PageCustomDimensionsSlot slot={p.match.params.slot} />
  },
  {
    path: PATHS.content.customDimensions.details,
    render: (p) => (
      <PageCustomDimensionsDetails
        slot={p.match.params.slot}
        value={p.match.params.value}
      />
    )
  },
  {
    path: PATHS.content.customDimensions.manage,
    render: () => <PageCustomDimensionsManager />
  },
  {
    path: PATHS.performanceNew.transactions,
    render: () => <PagePerformanceNewTransactions />
  },
  {
    path: PATHS.performanceNew.advertisers.overview,
    render: () => <PagePerformanceAdvertisers />
  },
  {
    path: PATHS.performanceNew.advertisers.details,
    render: () => <PagePerformanceAdvertiserDetails />
  },
  {
    path: PATHS.performanceNew.rates.overview,
    render: () => <PagePerformanceRates />
  },
  {
    path: PATHS.performanceNew.payouts.overview,
    render: () => <PagePerformancePayouts />
  },
  {
    path: PATHS.performanceNew.payouts.details.overview,
    render: (p) => <PagePerformancePayoutDetails payoutId={p.match.params.id} />
  },
  {
    path: PATHS.performanceNew.networks.overview,
    render: () => <PagePerformanceNetworks />
  },
  {
    path: PATHS.performanceNew.channels,
    render: () => <PagePerformanceChannels />
  },
  {
    path: PATHS.performanceNew.integrations,
    render: () => <PagePerformanceNewIntegrations />
  },
  {
    path: PATHS.performanceNew.rulesV2,
    render: () => <PagePerformanceRulesV3 />
  },
  {
    path: PATHS.performanceNew.activity,
    render: () => <PagePerformanceImportActivity />
  },

  {
    path: PATHS.settings.general,
    render: () => <PageSettingsNewGeneral />
  },
  {
    path: PATHS.settings.notifications,
    render: () => <PagesSettingsNewNotifications />
  },
  {
    path: PATHS.settings.profile,
    render: () => <PagesSettingsNewProfile />
  },
  {
    path: PATHS.settings.billing,
    render: () => <PagesSettingsNewBilling />
  },
  {
    path: PATHS.settings.browserExtension,
    render: () => <PagesSettingsNewBrowserExtension />
  },
  {
    path: PATHS.settings.tracking,
    render: () => <PageSettingsTracking />
  },
  {
    path: PATHS.settings.content,
    render: () => <PageSettingsContent />
  },
  {
    path: PATHS.settings.affiliateProgram,
    render: () => <PagesSettingsNewAffiliateProgram />
  },
  {
    path: PATHS.settings.users,
    render: () => <PageSettingsNewUserManagement />
  },
  {
    path: PATHS.settings.api,
    render: () => <PageSettingsApi />
  },
  {
    path: PATHS.settings.exports,
    render: () => <PageSettingsExports />
  },
  {
    path: PATHS.dashboard.overview,
    render: () => <PageDashboardOverviewV2 />
  },
  {
    path: PATHS.dashboard.realtime,
    render: () => <PageDashboardRealtime />
  },
  {
    path: PATHS.staff.overview,
    render: () => <PageStaff />
  },

  {
    path: PATHS.revisions.overview,
    render: () => <PageRevisionsOverview />
  },

  {
    path: PATHS.links.overview_v2,
    render: () => <PageLinksOverviewV2 />
  },
  {
    path: PATHS.links.amazonIssues,
    render: () => <PageAmazonIssuesReport />
  },
  {
    path: PATHS.links.inventory,
    render: () => <PageLinksInventory />
  },
  {
    path: PATHS.links.scans.history,
    render: () => <PageLinksScanHistory />
  },
  {
    path: PATHS.links.scans.details,
    render: (p) => <PageLinksScanDetails scanId={p.match.params.scanId} />
  },
  {
    path: PATHS.links.settings,
    render: () => <PageLinksSettings />
  },
  {
    path: PATHS.links.amazonSettings,
    render: () => <PageLinksAmazonSettings />
  },
  {
    path: PATHS.links.details.overview,
    render: (p) => (
      <PageLinkDetailsOverview productId={p.match.params.productId} />
    )
  },
  {
    path: PATHS.links.details.pages,
    render: (p) => <PageLinkDetailsPages productId={p.match.params.productId} />
  },
  {
    path: PATHS.links.details.audience,
    render: (p) => (
      <PageLinkDetailsAudience productId={p.match.params.productId} />
    )
  },
  {
    path: PATHS.inventory.issues,
    render: (p) => <PageInventoryIssues />
  },
  {
    path: PATHS.campaigns.overview,
    render: () => <CampaignsActive />
  },
  {
    path: PATHS.campaigns.complete,
    render: () => <CampaignsComplete />
  },
  {
    path: PATHS.campaigns.settings,
    render: () => <PageCampaignsSettings />
  },
  {
    path: PATHS.campaigns.teams,
    render: () => <CampaignsTeams />
  },
  {
    path: PATHS.campaigns.performance,
    render: () => <PageCampaignsPerformance />
  },
  {
    path: PATHS.campaigns.create,
    render: () => <CampaignCreator />
  },
  {
    path: PATHS.campaigns.report,
    render: (p) => <CampaignReport campaignId={p.match.params.campaignId} />
  },
  {
    path: PATHS.campaigns.details,
    render: (p) => <CampaignDetails campaignId={p.match.params.campaignId} />
  },
  {
    path: PATHS.audit.overview,
    render: () => <PageAuditOverview />
  },
  {
    path: PATHS.audit.details,
    render: (props) => {
      const siteUrl = decodeURIComponent(props.match.params.siteUrl);
      if (!isValidUrl(siteUrl)) {
        return <Centered height={500}>Invalid Site URL.</Centered>;
      }
      return <PageAuditDetails siteUrl={siteUrl} />;
    }
  },

  {
    path: PATHS.analytics.content,
    redirect: (p, r) => r.dashboard.overview.url()
  },
  {
    path: PATHS.analytics.audience,
    redirect: (p, r) => r.dashboard.overview.url()
  },
  {
    path: PATHS.analytics.traffic,
    redirect: (p, r) => r.dashboard.overview.url()
  },
  {
    path: PATHS.performance.overview,
    redirect: (p, r) => r.performanceNew.transactions.url()
  },
  {
    path: PATHS.performance.settings,
    redirect: (p, r) => r.performanceNew.integrations.url()
  },
  {
    path: PATHS.performance.reports,
    redirect: (p, r) => r.performanceNew.integrations.url()
  },
  {
    path: PATHS.performance.integrations,
    redirect: (p, r) => r.performanceNew.integrations.url()
  },
  {
    path: PATHS.productScans.overview,
    redirect: (p, r) => r.links.scans.history.url()
  },
  {
    path: PATHS.productScans.details,
    redirect: (p, r) => r.links.scans.details.url(p.match.params.scanId)
  },
  {
    path: PATHS.products.overview,
    redirect: (p, r) => r.links.overview_v2.url()
  },
  {
    path: PATHS.products.details,
    redirect: (p, r) => r.links.details.overview.url(p.match.params.productId)
  },
  {
    path: PATHS.pages.overview,
    redirect: (p, r) => r.content.overview.url()
  },
  {
    path: PATHS.pages.details,
    redirect: (p, r) =>
      r.content.details.trends.url(
        getQueryParam<string>(p.location.search, 'url', '')
      )
  },
  {
    path: PATHS.pages.audience,
    redirect: (p, r) =>
      r.content.details.audience.url(
        getQueryParam<string>(p.location.search, 'url', '')
      )
  },
  {
    path: PATHS.pages.heatmap,
    redirect: (p, r) =>
      r.content.details.heatmap.url(
        getQueryParam<string>(p.location.search, 'url', '')
      )
  },
  {
    path: PATHS.schedules.home,
    redirect: (p, r) => r.links.settings.url()
  },
  {
    path: PATHS.partners.overview,
    redirect: (p, r) => r.performanceNew.networks.overview.url()
  },
  {
    path: PATHS.partners.details,
    redirect: (p, r) =>
      r.performanceNew.networks.overview.url(p.match.params.partnerKey || '')
  },
  {
    path: PATHS.tools.linkGenerator,
    render: () => <PageToolsLinkGenerator />
  },
  {
    path: PATHS.docs.knowledgeBase,
    render: (p) => <PageDocsKnowledgeBase slug={p.match.params.slug} />
  },
  {
    path: PATHS.docs.api,
    render: () => <PageApiDocs />
  },
  {
    path: '/docs/',
    redirect: (p, r) => r.docs.knowledgeBase.url('getting-started')
  },
  {
    path: PATHS.recommendations.overview,
    render: () => <PageRecommendations />
  }
];

export interface INavCategory {
  id: string;
  route: string;
  icon: JSX.Element;
  disabled?: boolean;
  hidden?: boolean;
  isActive: () => boolean;
}

export interface INavCategoryGroup {
  id: string;
  children: INavCategory[];
}
