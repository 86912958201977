import React, { useCallback } from 'react';
import { ITeam } from '../../../../domainTypes/teams';
import { useCurrentUserScopes } from '../../../../services/currentUser';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { useMixpanel } from '../../../../services/mixpanel';
import { saveTeam } from '../../service/teams';
import { Button } from '@material-ui/core';
import { Trash2 } from 'react-feather';
import { TeamCard } from './TeamCard';

export const TeamDraft: React.FC<{
  team: ITeam;
  deleteDraft: (id: string) => void;
}> = ({ team, deleteDraft }) => {
  const scopes = useCurrentUserScopes();
  const { enqueueSnackbar } = useSnackbar();
  const mixpanel = useMixpanel();
  const canCreate = scopes.has('teams.create');
  const addTeam = useCallback(
    async (team: ITeam) => {
      try {
        mixpanel.track('teams_create', {
          teamId: team.teamId,
          spaceId: team.spaceId
        });
        await saveTeam(team);
        deleteDraft(team.teamId);
        enqueueSnackbar('Team created', { variant: 'success' });
      } catch (e) {
        console.error('Failed to create team', e);
        enqueueSnackbar('Failed to create team', { variant: 'error' });
      }
    },
    [deleteDraft, enqueueSnackbar, mixpanel]
  );
  return (
    <TeamCard
      team={team}
      deleteButton={
        <Button
          color="default"
          style={{ opacity: 0.5 }}
          onClick={() => deleteDraft(team.teamId)}
        >
          <Trash2 size={16} /> &nbsp; Delete team draft ...
        </Button>
      }
      saveLabel="Create team"
      saveTeam={addTeam}
      canModify={canCreate}
      autoFocus
    />
  );
};
