import { setDoc, store } from '../../../services/db';
import { FS } from '../../../versions';
import { IForecast, ITeam } from '../../../domainTypes/teams';
import { Doc, generateToDocFn } from '../../../domainTypes/document';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../services/firecache/collectionListener';
import { Timestamp } from '../../../domainTypes/time';
import {
  createDocumentListenerGetter,
  useDocumentListener
} from '../../../services/firecache/documentListener';

const teamsStore = (spaceId: string) =>
  store()
    .collection(FS.teams)
    .where('spaceId', '==', spaceId)
    .orderBy('createdAt', 'desc');

const teamsCollectionListener = createCollectionListenerStore(
  (spaceId) => new CollectionListener(teamsStore(spaceId), toTeamDoc)
);

export const useTeamsCollection = (spaceId: string) =>
  useCollectionListener(teamsCollectionListener(spaceId));

const toTeamDoc = generateToDocFn<ITeam>();

const toTeamId = (spaceId: string, teamId: string) => `${spaceId}_${teamId}`;

const teamStore = createDocumentListenerGetter(
  (docId: string) => store().collection(FS.teams).doc(docId),
  toTeamDoc
);

export const useTeam = (spaceId: string, teamId: string) =>
  useDocumentListener(teamStore(toTeamId(spaceId, teamId)));

interface TeamFields {
  teamId: string;
  spaceId: string;
  name: string;
  users: string[];
  forecasts: IForecast[];
  createdBy: string;
  createdAt: Timestamp;
}

export const saveTeam = async ({ teamId, spaceId, ...rest }: TeamFields) => {
  const doc: Doc<ITeam> = {
    collection: FS.teams,
    id: toTeamId(spaceId, teamId),
    data: {
      teamId,
      spaceId,
      ...rest
    }
  };
  await setDoc(doc);
};

export const removeTeam = async (spaceId: string, teamId: string) =>
  store().collection(FS.teams).doc(toTeamId(spaceId, teamId)).delete();
