import Typography from '@material-ui/core/Typography';
import { capitalize } from 'lodash';
import React from 'react';
import {
  AnalyticsFilter,
  ISOTimeRange
} from '../../../../domainTypes/analytics_v2';
import {
  ClickDimensionName,
  slotTitle
} from '../../../../domainTypes/customDimensions';
import { FilterDefinition } from '../../../../domainTypes/filters';
import { css, styled } from '../../../../emotion';
import { Metric } from '../../../../services/analyticsV2/metrics';
import { ARTICLE_IDS, Beacon } from '../../../../services/beacon';
import { useCustomDimensions } from '../../../../services/customDimensions';
import { HelpIcon } from '../../../HelpIcon';
import { FilterDrawerRenderContext } from '../Drawer/types';
import { Dimension, useDimensionName, useDimensionTitle } from '../filters';
import { useFilters } from '../hooks';
import { AdvertiserMenu } from './AdvertiserMenu';
import { ChannelMenu } from './ChannelMenu';
import { ClickDataMenu } from './ClickDataMenu';
import { CountryMenu } from './CountryMenu';
import { DeviceMenu } from './DeviceMenu';
import { IntegrationIdMenu } from './IntegrationIdMenu';
import { PayoutIdMenu } from './PayoutIdMenu';
import { PayoutStatusMenu } from './PayoutStatusMenu';
import { PlatformMenu } from './PlatformMenu';
import { ReferrersMenu } from './ReferrersMenu';
import { SiteMenu } from './SiteMenu';
import { TagMenu } from './TagMenu';
import { TransactionStatusMenu } from './TransactionStatusMenu';
import { TransactionTypeMenu } from './TransactionTypeMenu';
import { UtmMenu } from './UtmMenu';
import { CampaignManagerMenu } from './Campaigns/ManagerMenu';
import { CampaignAdvertiserMenu } from './Campaigns/AdvertiserMenu';
import { CampaignStatusMenu } from './Campaigns/StatusMenu';
import { CampaignTeamMenu } from './Campaigns/TeamMenu';

export interface DimensionMenuProps<T extends FilterDefinition> {
  filters: AnalyticsFilter[];
  orderBy: Metric;
  range: ISOTimeRange;
  definition: T;
  onSave: (definition: T) => void;
  renderContext?: FilterDrawerRenderContext;
}

export type DimensionMenuComponent<T extends FilterDefinition> = React.FC<
  DimensionMenuProps<T>
>;

interface DescriptionProps {
  definition: FilterDefinition;
}

const DimensionDescriptionBox = styled('div')`
  color: ${(t) => t.theme.palette.grey['700']};
`;

const LearnMore = (
  <HelpIcon
    color="gray"
    onClick={() => {
      Beacon('article', ARTICLE_IDS.content.filters);
    }}
  >
    Learn more
  </HelpIcon>
);

// NOTE: Maybe we want an optional, user defined description parameter in Custom Dimensions settings?
const CustomDimensionDescription = ({ slot }: { slot: ClickDimensionName }) => {
  const [dimensions = {}] = useCustomDimensions();
  const title = capitalize(dimensions[slot]?.name ?? slotTitle(slot));
  const description = dimensions[slot]?.description ?? '';
  return (
    <DimensionDescriptionBox>
      <strong>{title}</strong>: {description}
    </DimensionDescriptionBox>
  );
};

export const DimensionDescription: React.FC<DescriptionProps> = ({
  definition
}) => {
  switch (definition.k) {
    case 'campaign_status':
      // TODO: copy
      return (
        <DimensionDescriptionBox>
          <strong>Campaign Status</strong> is the current state of the campaign.
        </DimensionDescriptionBox>
      );
    case 'campaign_advertiser':
      // TODO: copy
      return (
        <DimensionDescriptionBox>
          <strong>Campaign Advertiser</strong> is the company or brand that
          you’re partnered with.
        </DimensionDescriptionBox>
      );
    case 'campaign_team':
      // TODO: copy
      return (
        <DimensionDescriptionBox>
          <strong>Campaign Team</strong> is the group of people working on
          campaigns together.
        </DimensionDescriptionBox>
      );
    case 'campaign_manager':
      // TODO: copy
      return (
        <DimensionDescriptionBox>
          <strong>Campaign Manager</strong> is the person responsible for the
          campaign
        </DimensionDescriptionBox>
      );
    case 'integration_id':
      return (
        <DimensionDescriptionBox>
          <strong>Integration ID</strong> is the specific API connection to a
          platform. Use this to split revenue from multiple connections to the
          same platform. {LearnMore}
        </DimensionDescriptionBox>
      );
    case 'advertiser':
      return (
        <DimensionDescriptionBox>
          <strong>Advertiser</strong> is the company or brand that you’re
          partnered with. Options are sorted by earnings. {LearnMore}
        </DimensionDescriptionBox>
      );
    case 'channel':
      return (
        <DimensionDescriptionBox>
          <strong>Channel</strong> is the source of your commissions, such as
          websites, newsletters, or social profiles. {LearnMore}
        </DimensionDescriptionBox>
      );
    case 'click_data_01':
      return <CustomDimensionDescription slot="click_data_01" />;
    case 'click_data_02':
      return <CustomDimensionDescription slot="click_data_02" />;
    case 'click_data_03':
      return <CustomDimensionDescription slot="click_data_03" />;
    case 'click_data_04':
      return <CustomDimensionDescription slot="click_data_04" />;
    case 'click_data_05':
      return <CustomDimensionDescription slot="click_data_05" />;
    case 'click_data_06':
      return <CustomDimensionDescription slot="click_data_06" />;
    case 'click_data_07':
      return <CustomDimensionDescription slot="click_data_07" />;
    case 'click_data_08':
      return <CustomDimensionDescription slot="click_data_08" />;
    case 'click_data_09':
      return <CustomDimensionDescription slot="click_data_09" />;
    case 'click_data_10':
      return <CustomDimensionDescription slot="click_data_10" />;
    case 'country':
      return (
        <DimensionDescriptionBox>
          <strong>Country</strong> is the country where the user was located
          when they visited the site or made a purchase. {LearnMore}
        </DimensionDescriptionBox>
      );
    case 'device':
      return (
        <DimensionDescriptionBox>
          <strong>Device</strong> indicates which type of device the user was on
          when they visited the site or made a purchase. {LearnMore}
        </DimensionDescriptionBox>
      );
    case 'utm_campaign':
      return (
        <DimensionDescriptionBox>
          <strong>UTM campaign</strong> shows the URL's utm_campaign parameter
          for traffic that landed on the page. {LearnMore}
        </DimensionDescriptionBox>
      );
    case 'utm_medium':
      return (
        <DimensionDescriptionBox>
          <strong>UTM medium</strong> shows the URL's utm_medium parameter for
          traffic that landed on the page. {LearnMore}
        </DimensionDescriptionBox>
      );
    case 'utm_source':
      return (
        <DimensionDescriptionBox>
          <strong>UTM source</strong> shows the URL's utm_source parameter for
          traffic that landed on the page. {LearnMore}
        </DimensionDescriptionBox>
      );
    case 'utm_term':
      return (
        <DimensionDescriptionBox>
          <strong>UTM term</strong> shows the URL's utm_term parameter for
          traffic that landed on the page. {LearnMore}
        </DimensionDescriptionBox>
      );
    case 'utm_content':
      return (
        <DimensionDescriptionBox>
          <strong>UTM content</strong> shows the URL's utm_content parameter for
          traffic that landed on the page. {LearnMore}
        </DimensionDescriptionBox>
      );
    case 'tag':
      return (
        <DimensionDescriptionBox>
          <strong>Tags</strong> are labels within a group that you can use to
          organize your data. {LearnMore}
        </DimensionDescriptionBox>
      );
    case 'platform':
      return (
        <DimensionDescriptionBox>
          <strong>Platform</strong> refers to the affiliate network or software
          of your commissions and links. {LearnMore}
        </DimensionDescriptionBox>
      );
    case 'payout_id':
      return (
        <DimensionDescriptionBox>
          <strong>Payout ID</strong> is the unique identifier for the payout
          that corresponds to this transaction. {LearnMore}
        </DimensionDescriptionBox>
      );
    case 'payout_status':
      return (
        <DimensionDescriptionBox>
          <strong>Payout status</strong> of the commission. Disabled options
          means no commissions of that status in the current view. {LearnMore}
        </DimensionDescriptionBox>
      );
    case 'transaction_status':
      return (
        <DimensionDescriptionBox>
          <strong>Transaction status</strong> of the commission. Disabled
          options means no commissions of that status in the current view.{' '}
          {LearnMore}
        </DimensionDescriptionBox>
      );
    case 'transaction_type':
      return (
        <DimensionDescriptionBox>
          <strong>Transaction type</strong> of the commission. Bonuses are
          filtered out by default. Disabled options means no commissions of that
          type exist in the current view. {LearnMore}
        </DimensionDescriptionBox>
      );
    case 'site':
      return (
        <DimensionDescriptionBox>
          <strong>Sites</strong> are your properties where you’re promoting
          affiliate links or content. {LearnMore}
        </DimensionDescriptionBox>
      );
    case 'referrer': {
      const mode = definition.v.mode;
      switch (mode) {
        case 'domains':
          return (
            <DimensionDescriptionBox>
              <strong>Referrers</strong> are specific domains that sent traffic
              to your website, excluding direct/internal. {LearnMore}
            </DimensionDescriptionBox>
          );
        case 'group':
          return (
            <DimensionDescriptionBox>
              <strong>Referrer groups</strong> are sets of domains that drove
              traffic to your website. {LearnMore}
            </DimensionDescriptionBox>
          );
      }
    }
  }
};

const useIsFirstChip = (dimension: Dimension) => {
  const filters = useFilters();
  const index = filters.findIndex((f) => f.dimension === dimension);
  if (index === -1) {
    // It is a placeholder chip, check if it is only one chip
    return filters.length === 0;
  }
  return index === 0;
};

export const DimensionTitle: React.FC<{ dimension: Dimension }> = ({
  dimension,
  children
}) => {
  const isFirst = useIsFirstChip(dimension);
  let title = useDimensionTitle(dimension);
  let name = useDimensionName(dimension);
  const titleWithPrefix = isFirst ? title : `And ${name}`;

  return (
    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
      {titleWithPrefix} is
      <span className={css((t) => ({ marginLeft: t.spacing(0.5) }))}>
        {children}
      </span>
    </Typography>
  );
};

export const DimensionMenu: React.FC<DimensionMenuProps<FilterDefinition>> = ({
  definition,
  filters,
  onSave,
  orderBy,
  range,
  renderContext
}) => {
  switch (definition.k) {
    case 'campaign_status':
      return <CampaignStatusMenu onSave={onSave} definition={definition} />;
    case 'campaign_advertiser':
      return <CampaignAdvertiserMenu onSave={onSave} definition={definition} />;
    case 'campaign_team':
      return <CampaignTeamMenu onSave={onSave} definition={definition} />;
    case 'campaign_manager':
      return <CampaignManagerMenu onSave={onSave} definition={definition} />;
    case 'integration_id':
      return (
        <IntegrationIdMenu
          onSave={onSave}
          definition={definition}
          filters={filters}
          orderBy={orderBy}
          range={range}
        />
      );
    case 'advertiser':
      return (
        <AdvertiserMenu
          onSave={onSave}
          definition={definition}
          filters={filters}
          orderBy={orderBy}
          range={range}
        />
      );
    case 'channel':
      return (
        <ChannelMenu
          filters={filters}
          orderBy={orderBy}
          range={range}
          definition={definition}
          onSave={onSave}
        />
      );
    case 'click_data_01':
      return (
        <ClickDataMenu
          slot="click_data_01"
          filters={filters}
          orderBy={orderBy}
          range={range}
          definition={definition}
          onSave={onSave}
        />
      );
    case 'click_data_02':
      return (
        <ClickDataMenu
          slot="click_data_02"
          filters={filters}
          orderBy={orderBy}
          range={range}
          definition={definition}
          onSave={onSave}
        />
      );
    case 'click_data_03':
      return (
        <ClickDataMenu
          slot="click_data_03"
          filters={filters}
          orderBy={orderBy}
          range={range}
          definition={definition}
          onSave={onSave}
        />
      );
    case 'click_data_04':
      return (
        <ClickDataMenu
          slot="click_data_04"
          filters={filters}
          orderBy={orderBy}
          range={range}
          definition={definition}
          onSave={onSave}
        />
      );
    case 'click_data_05':
      return (
        <ClickDataMenu
          slot="click_data_05"
          filters={filters}
          orderBy={orderBy}
          range={range}
          definition={definition}
          onSave={onSave}
        />
      );
    case 'click_data_06':
      return (
        <ClickDataMenu
          slot="click_data_06"
          filters={filters}
          orderBy={orderBy}
          range={range}
          definition={definition}
          onSave={onSave}
        />
      );
    case 'click_data_07':
      return (
        <ClickDataMenu
          slot="click_data_07"
          filters={filters}
          orderBy={orderBy}
          range={range}
          definition={definition}
          onSave={onSave}
        />
      );
    case 'click_data_08':
      return (
        <ClickDataMenu
          slot="click_data_08"
          filters={filters}
          orderBy={orderBy}
          range={range}
          definition={definition}
          onSave={onSave}
        />
      );
    case 'click_data_09':
      return (
        <ClickDataMenu
          slot="click_data_09"
          filters={filters}
          orderBy={orderBy}
          range={range}
          definition={definition}
          onSave={onSave}
        />
      );
    case 'click_data_10':
      return (
        <ClickDataMenu
          slot="click_data_10"
          filters={filters}
          orderBy={orderBy}
          range={range}
          definition={definition}
          onSave={onSave}
        />
      );
    case 'country':
      return (
        <CountryMenu
          filters={filters}
          orderBy={orderBy}
          range={range}
          definition={definition}
          onSave={onSave}
        />
      );
    case 'device':
      return (
        <DeviceMenu
          filters={filters}
          orderBy={orderBy}
          range={range}
          definition={definition}
          onSave={onSave}
        />
      );
    case 'utm_campaign':
      return (
        <UtmMenu
          parameter="campaign"
          onSave={onSave}
          definition={definition}
          filters={filters}
          orderBy={orderBy}
          range={range}
        />
      );
    case 'utm_medium':
      return (
        <UtmMenu
          parameter="medium"
          onSave={onSave}
          definition={definition}
          filters={filters}
          orderBy={orderBy}
          range={range}
        />
      );
    case 'utm_source':
      return (
        <UtmMenu
          parameter="source"
          onSave={onSave}
          definition={definition}
          filters={filters}
          orderBy={orderBy}
          range={range}
        />
      );
    case 'utm_term':
      return (
        <UtmMenu
          parameter="term"
          onSave={onSave}
          definition={definition}
          filters={filters}
          orderBy={orderBy}
          range={range}
        />
      );
    case 'utm_content':
      return (
        <UtmMenu
          parameter="content"
          onSave={onSave}
          definition={definition}
          filters={filters}
          orderBy={orderBy}
          range={range}
        />
      );
    case 'referrer':
      return (
        <ReferrersMenu
          onSave={onSave}
          definition={definition}
          filters={filters}
          orderBy={orderBy}
          range={range}
        />
      );
    case 'tag':
      return (
        <TagMenu
          onSave={onSave}
          definition={definition}
          filters={filters}
          orderBy={orderBy} // NOTE: we don't use it inside TagMenu!
          range={range}
        />
      );
    case 'platform':
      return (
        <PlatformMenu
          onSave={onSave}
          definition={definition}
          filters={filters}
          orderBy={orderBy} // NOTE: we don't use it inside PlatformMenu
          range={range}
          renderContext={renderContext}
        />
      );
    case 'site':
      return (
        <SiteMenu
          onSave={onSave}
          definition={definition}
          filters={filters}
          orderBy={orderBy}
          range={range}
        />
      );
    case 'payout_id':
      return (
        <PayoutIdMenu
          onSave={onSave}
          definition={definition}
          filters={filters}
          orderBy={orderBy} // NOTE: we don't use it inside PayoutStatusMenu
          range={range}
        />
      );
    case 'payout_status':
      return (
        <PayoutStatusMenu
          onSave={onSave}
          definition={definition}
          filters={filters}
          orderBy={orderBy} // NOTE: we don't use it inside PayoutStatusmenu
          range={range}
        />
      );
    case 'transaction_status':
      return (
        <TransactionStatusMenu
          onSave={onSave}
          definition={definition}
          filters={filters}
          range={range}
          orderBy={orderBy} // NOTE: we don't use it inside TransactionStatusMenu
        />
      );
    case 'transaction_type':
      return (
        <TransactionTypeMenu
          onSave={onSave}
          definition={definition}
          filters={filters}
          range={range}
          orderBy={orderBy} // NOTE: we don't use it inside TransactionTypeMenu
        />
      );
  }
};
