import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Json } from '../../../../admin/components/Json';
import { useTimeframe } from '../../../../components/analytics_v2/Timeframe';
import { Loader } from '../../../../components/Loader';
import { AnalyticsQuery } from '../../../../domainTypes/analytics_v2';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../services/currentUser';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { PageBodyInventory } from '../../components/PageBodyInventory';

const Body = () => {
  const { space } = useCurrentUser();
  const { range } = useTimeframe();
  const q = useMemo<AnalyticsQuery>(() => {
    return {
      select: ['c', 'ctr', 'commission_sum_net', 'agg_uniq_page_url'],
      range,
      groupBy: [
        'link_id',
        'link_dest_url',
        'p_title',
        'p_image_url',
        'p_availability',
        'p_catalog_uid'
      ],
      paginate: {
        page: 1,
        limit: 10
      },
      filters: [
        { field: 'p_availability', condition: 'in', values: ['out_of_stock'] }
      ],
      orderBy: [{ field: 'c', direction: 'DESC' }]
    };
  }, [range]);
  const [data, loading] = useAnalyticsQueryV2(space.id, q, {
    noCache: true,
    logMode: 'full'
  });
  return (
    <PageBodyInventory>
      {loading && <Loader height={500} />}
      {data && !loading && <Json data={data} />}
    </PageBodyInventory>
  );
};

export const PageInventoryIssues = () => {
  useTrackMixpanelView('view_inventory_issues');

  return (
    <>
      <Helmet>
        <title>Inventory Issues | Affilimate</title>
      </Helmet>

      <Body />
    </>
  );
};
