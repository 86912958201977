import { styled } from '../../../../emotion';
import {
  Step,
  StepButton,
  StepContent,
  StepLabel,
  Stepper
} from '@material-ui/core';
import { ICampaign } from '../../../../domainTypes/campaigns';
import { fromMoment } from '../../../../services/time';
import { isEmpty, negate } from 'lodash';
import { readCampaign, saveCampaign } from '../../service';
import React, { useCallback } from 'react';
import { LoadingValue } from '../../../../services/db';
import { useCurrentUser } from '../../../../services/currentUser';
import { FlexContainer } from '../../../../layout/Flex';
import Typography from '@material-ui/core/Typography';
import { LimitedWidth } from '../../../../layout/PageBody';
import { CampaignFormSteps } from './CampaignFormSteps';
import {
  CampaignContent,
  CampaignDetails,
  CampaignFormFields,
  CampaignLinks
} from './form-types';
import { Caption, Stack } from './form-components';

import {
  CampaignArticlesChip,
  CampaignDateChip,
  CampaignGoalChip,
  CampaignPlacementsChip
} from '../chips';
import { DeleteCampaignButton } from '../DeleteCampaignButton';
import { useNumberQueryParam } from '../../../../routes';
import { Moment } from 'moment-timezone';

const LEFT_COLUMN_WIDTH = 215;
const TOTAL_WIDTH = 1200;

const mergeTimeframe = (
  timeframe: { start: Moment; end: Moment } | null | undefined,
  campaign: ICampaign
) => {
  if (timeframe === undefined) return campaign.timeframe;
  if (timeframe === null) return null;
  return {
    start: fromMoment(timeframe.start),
    end: fromMoment(timeframe.end)
  };
};

// TODO: require more fields there like name etc.
const mergeFieldsToCampaign = (
  fields: Partial<CampaignFormFields>,
  campaign: ICampaign
): Omit<ICampaign, 'id' | 'spaceId'> => {
  const timeframe = mergeTimeframe(fields.timeframe, campaign);
  return {
    name: fields.name ?? campaign.name,
    timeframe,
    incentives: fields.incentives ?? campaign.incentives,
    goals: fields.goals ?? campaign.goals,
    advertisers: fields.advertisers ?? campaign.advertisers,
    team: fields.team ?? campaign.team,
    managers: fields.managers ?? campaign.managers,
    pageUrls: fields.pageUrls
      ? // TODO: remove filter?
        fields.pageUrls.filter(negate(isEmpty))
      : campaign.pageUrls,
    links: fields.links ?? campaign.links
  };
};

const savePartialCampaignFields = async (
  spaceId: string,
  slug: string,
  fields: Partial<CampaignFormFields>,
  campaign: ICampaign
) => {
  await saveCampaign(spaceId, slug, mergeFieldsToCampaign(fields, campaign));
};

const CampaignCreatorBody = styled('div')`
  display: grid;
  grid-template-columns: ${LEFT_COLUMN_WIDTH}px 1fr;
  grid-column-gap: ${({ theme }) => theme.spacing(4)}px;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

const steps = [
  {
    label: 'Define campaign',
    description: 'Define your campaign’s incentives, goals, and timeframe.'
  },
  {
    label: 'Select campaign content',
    description: 'Specify which onsite articles include campaign links.'
  },
  {
    label: 'Select link placements',
    description: 'Select which links to include in this campaign.'
  },
  {
    label: 'Preview campaign',
    description: 'Review and test your campaign settings and generated links.'
  }
];

const CampaignSummary = ({
  campaign: rawCampaign
}: {
  campaign: LoadingValue<ICampaign>;
}) => {
  const [data, loading] = rawCampaign;
  if (!data || loading) {
    return null;
  }
  const campaign = readCampaign(data);

  if (!campaign.name) {
    return null;
  }

  return (
    <Stack>
      <Typography variant="body1">
        <strong>{campaign.name}</strong>
      </Typography>
      {campaign.advertisers.map((advertiser) => (
        <Caption>{advertiser.name}</Caption>
      ))}
      <FlexContainer wrap="wrap">
        <CampaignDateChip campaign={campaign} />
        {campaign.goals.map((goal) => (
          <CampaignGoalChip goal={goal} />
        ))}
        <CampaignArticlesChip campaign={campaign} />
        <CampaignPlacementsChip campaign={campaign} />
      </FlexContainer>
      <DeleteCampaignButton campaign={campaign} />
    </Stack>
  );
};

export const CampaignWizard: React.FC<{
  campaign: LoadingValue<ICampaign>;
  onSuccess: () => void;
}> = ({ campaign, onSuccess }) => {
  const { space } = useCurrentUser();

  const [activeStep, setActiveStep] = useNumberQueryParam('step', 0);

  const back = useCallback(() => {
    setActiveStep(Math.max(activeStep - 1, 0));
  }, [setActiveStep, activeStep]);

  const saveDetails = useCallback(
    async (details: CampaignDetails, campaign: ICampaign) => {
      await savePartialCampaignFields(space.id, campaign.id, details, campaign);
      setActiveStep(1);
    },
    [setActiveStep, space.id]
  );

  const saveContent = useCallback(
    async (content: CampaignContent, campaign: ICampaign) => {
      await savePartialCampaignFields(space.id, campaign.id, content, campaign);
      setActiveStep(2);
    },
    [setActiveStep, space.id]
  );

  const saveLinks = useCallback(
    async (links: CampaignLinks, campaign: ICampaign) => {
      await savePartialCampaignFields(space.id, campaign.id, links, campaign);
      setActiveStep(3);
    },
    [setActiveStep, space.id]
  );

  return (
    <LimitedWidth width={TOTAL_WIDTH}>
      <CampaignCreatorBody>
        <FlexContainer
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            style={{ backgroundColor: 'transparent', padding: `12px 0` }}
          >
            {steps.map((step, index) => (
              <Step key={index}>
                <StepButton onClick={() => setActiveStep(index)}>
                  <StepLabel>{step.label}</StepLabel>
                </StepButton>
                <StepContent>
                  <Typography color="textSecondary" variant="caption">
                    {step.description}
                  </Typography>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          <CampaignSummary campaign={campaign} />
        </FlexContainer>
        <CampaignFormSteps
          campaign={campaign}
          back={back}
          step={activeStep}
          onSuccess={onSuccess}
          saveCampaignDetails={saveDetails}
          saveCampaignContent={saveContent}
          saveCampaignLinks={saveLinks}
        />
      </CampaignCreatorBody>
    </LimitedWidth>
  );
};
