import { useMemo } from 'react';
import {
  AnalyticsResponse,
  TransactionsQuery,
  TransactionsResponse
} from '../../domainTypes/analytics_v2';
import { usePromise } from '../../hooks/usePromise';
import { CF } from '../../versions';
import { toChecksum } from '../checksum';
import { callFirebaseFunction } from '../firebaseFunctions';
import { isLocalhost } from '../localhost';
import { getCache, useAnalyticsV2CacheRevision } from './cache';
import { createLogFn, getLogModeDefault } from './logs';

const CF_NAME = 'analytics_v2-transactions';

const isExpired = (n: number, expiresAt: number | null) =>
  expiresAt !== null && n > expiresAt;

const logQuery = createLogFn(CF_NAME, 'Transactions V2 Query', 'TV2Q');

type AnalyticsOpts = Partial<{
  noCache: boolean;
  cacheExpirationDuration: number; // in ms
  logMode: 'full' | 'compact' | 'off';
  logLabel: string;
}>;

export const queryTransactionsV2 = <T = TransactionsResponse>(
  spaceId: string,
  q: TransactionsQuery,
  opts: AnalyticsOpts = {}
) => {
  const logMode = opts.logMode || (isLocalhost() ? 'compact' : 'off');
  const n = Date.now();
  const request = () =>
    callFirebaseFunction<AnalyticsResponse>(CF_NAME, {
      spaceId,
      q
    });

  const getRequestCached = () => {
    if (opts.noCache) {
      return {
        cached: false,
        promise: (request() as unknown) as Promise<T>
      };
    }
    const cache = getCache(spaceId, 'transactions');
    const checksum = toChecksum({ spaceId, q });
    let cached = false;
    if (!cache[checksum] || isExpired(n, cache[checksum].expiresAt)) {
      cache[checksum] = {
        expiresAt: opts.cacheExpirationDuration
          ? n + opts.cacheExpirationDuration
          : null,
        promise: request()
      };
    } else {
      cached = true;
    }
    return {
      promise: (cache[checksum].promise as unknown) as Promise<T>,
      cached
    };
  };

  const req = getRequestCached();
  if (logMode !== 'off') {
    req.promise.then((res) => {
      logQuery(res as any, n, req.cached, logMode, opts.logLabel);
      return res;
    });
  }
  return req.promise;
};

export const useTransactionsQueryV2 = (
  spaceId: string,
  q: TransactionsQuery,
  opts?: AnalyticsOpts
) => {
  // A bit of a weird dance - but with this we avoid that the caller needs to memoize the options
  const noCache = opts?.noCache ?? false;
  const cacheExpirationDuration = opts?.cacheExpirationDuration ?? 0;
  const logMode = opts?.logMode ?? getLogModeDefault();
  const logLabel = opts?.logLabel ?? '';
  const optsWithDefaults: AnalyticsOpts = useMemo(
    () => ({
      noCache,
      cacheExpirationDuration,
      logMode,
      logLabel
    }),

    [noCache, cacheExpirationDuration, logMode, logLabel]
  );
  const revision = useAnalyticsV2CacheRevision(spaceId);
  return usePromise(() => queryTransactionsV2(spaceId, q, optsWithDefaults), [
    spaceId,
    q,
    optsWithDefaults,
    revision
  ]);
};

export const exportTransactions = async (
  spaceId: string,
  q: Omit<TransactionsQuery, 'select' | 'paginate'>
) => {
  await callFirebaseFunction(CF.files.exportTransactions, {
    spaceId,
    q: {
      ...q,
      select: [] // set by the backend atm
    }
  });
};
