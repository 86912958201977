import { Card, Tooltip, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useMemo } from 'react';
import { Info } from 'react-feather';
import { AlertBox } from '../../../../components/AlertBox';
import { metricCell } from '../../../../components/analytics_v2/MetricCell';
import { useTimeframe } from '../../../../components/analytics_v2/Timeframe';
import {
  AnalyticsFilter,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison
} from '../../../../domainTypes/analytics_v2';
import { css, styled } from '../../../../emotion';
import { FlexContainer } from '../../../../layout/Flex';
import { useChannelIdGrouper } from '../../../../services/analyticsV2/groups';
import {
  Metric,
  metricDescription,
  metricTitle
} from '../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../services/currentUser';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import { toLinkSearch, toProductLinkSearch, useLinksFilters } from './service';

const TotalsGrid = styled(Card)`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;

  // Handle mobile view using mui breakpoints
  @media (max-width: 600px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const SKELETON_WIDTH = 35;

const TotalsCellWithAnalyticsRow = ({
  row,
  metric,
  loading
}: {
  row: AnalyticsResponseRowWithComparison | null;
  metric: Metric;
  loading: boolean;
}) => {
  const currency = useSpaceCurrency();
  return (
    <FlexContainer direction="column" className={css(() => ({ flexGrow: 1 }))}>
      <Typography variant="caption" color="textSecondary">
        {metricTitle(metric)}{' '}
        <Tooltip title={metricDescription(metric)} placement="top">
          <Info size={12} />
        </Tooltip>
      </Typography>
      {row ? (
        metricCell(metric, row, {
          currency: currency,
          showComparison: true
        })
      ) : loading ? (
        <Skeleton variant="text" width={SKELETON_WIDTH} />
      ) : (
        <Typography variant="body2">-</Typography>
      )}
    </FlexContainer>
  );
};

export const LinkTotals = ({
  linkSearch,
  mode,
  additionalFilters
}: {
  linkSearch: string;
  mode: 'links' | 'products';
  additionalFilters?: AnalyticsFilter[];
}) => {
  const { space } = useCurrentUser();
  const filters = useLinksFilters(additionalFilters);
  const { range, compare } = useTimeframe();
  const { columnTransformers } = useChannelIdGrouper();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      select: [
        'count_uniq_link_id',
        'c',
        'commission_sum_net',
        'gmv_sum_net',
        'epc_net',
        'avg_rate_net',
        'quantity_net',
        'aov_net'
      ],
      range,
      compare,
      filters,
      search:
        mode === 'links'
          ? toLinkSearch(linkSearch)
          : toProductLinkSearch(linkSearch),
      columnTransformers: columnTransformers(space)
    };
  }, [range, filters, columnTransformers, space, linkSearch, compare, mode]);
  const [data, loading, error] = useAnalyticsQueryV2(space.id, query, {
    logMode: 'full',
    logLabel: 'TotalsRow'
  });

  if (error) {
    return <AlertBox variant="error">Error loading totals</AlertBox>;
  }

  return (
    <TotalsGrid>
      <TotalsCellWithAnalyticsRow
        metric="count_uniq_link_id"
        row={data ? data.rows[0] : null}
        loading={loading}
      />
      <TotalsCellWithAnalyticsRow
        metric="c"
        row={data ? data.rows[0] : null}
        loading={loading}
      />
      <TotalsCellWithAnalyticsRow
        metric="commission_sum_net"
        row={data ? data.rows[0] : null}
        loading={loading}
      />
      <TotalsCellWithAnalyticsRow
        metric="gmv_sum_net"
        row={data ? data.rows[0] : null}
        loading={loading}
      />
      <TotalsCellWithAnalyticsRow
        metric="epc_net"
        row={data ? data.rows[0] : null}
        loading={loading}
      />
      <TotalsCellWithAnalyticsRow
        metric="quantity_net"
        row={data ? data.rows[0] : null}
        loading={loading}
      />
      <TotalsCellWithAnalyticsRow
        metric="aov_net"
        row={data ? data.rows[0] : null}
        loading={loading}
      />
      <TotalsCellWithAnalyticsRow
        metric="avg_rate_net"
        row={data ? data.rows[0] : null}
        loading={loading}
      />
    </TotalsGrid>
  );
};
