import { startCase } from 'lodash';
import { IEarningMinimal } from '../../../domainTypes/performance';

export type AdvertiserGrouper =
  | 'brand'
  | 'partner_product_id'
  | 'partner_product_name'
  | 'partner_product_category'
  | 'seller';

export const productNameFieldToEnglish = (
  field: string,
  grouper: AdvertiserGrouper
) => {
  if (field.startsWith('http')) {
    return field.substr(field.lastIndexOf('/'), field.length);
  }

  return field;
};

export const earningsMinimalFieldtoEnglish = (
  field: keyof IEarningMinimal
): string => {
  switch (field) {
    case 'ct':
      return 'Earnings';
    case 'oct':
      return 'Order count';
    case 'qt':
      return 'Quantity';
    case 'ac':
      return 'Average commission';
    case 'pt':
      return 'Sales volume';
    default:
      return field;
  }
};

export const productGrouperToEnglish = (grouper: AdvertiserGrouper) => {
  if (grouper === 'partner_product_name') {
    return 'Product name';
  }
  if (grouper === 'partner_product_id') {
    return 'Product ID';
  }

  if (grouper === 'partner_product_category') {
    return 'Category';
  }

  return startCase(grouper);
};

export const productGrouperToEnglishHeading = (
  groupedBy: AdvertiserGrouper
) => {
  return groupedBy === 'partner_product_category'
    ? 'Top categories'
    : groupedBy === 'brand'
    ? 'Top brands'
    : groupedBy === 'seller'
    ? 'Top seller'
    : 'Top products sold';
};
