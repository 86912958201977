import { useTeam } from '../../service/teams';
import { useCurrentUser } from '../../../../services/currentUser';
import React from 'react';
import { WithShape } from '../../../../components/Charts/Util';
import { getStableRandomColor } from '../../../../services/color';
import { Skeleton } from '@material-ui/lab';
import { Truncated } from '../../../../components/Truncated';
import { Dash } from '../../../../components/Table/CountCell';

export const TeamWithColor: React.FC<{ teamId: string }> = ({ teamId }) => {
  const { space } = useCurrentUser();
  const [team, loading] = useTeam(space.id, teamId);
  if (loading) {
    return <Skeleton />;
  }
  if (!team) {
    return <Dash size={12} />;
  }
  return (
    <Truncated title={team.data.name}>
      <WithShape large color={getStableRandomColor(team.data.teamId)}>
        {team.data.name}
      </WithShape>
    </Truncated>
  );
};
