import { compact } from 'lodash';
import moment from 'moment-timezone';
import { useMemo } from 'react';
import { ISOTimeRange } from '../../domainTypes/analytics_v2';
import {
  IProductCatalogRowV1,
  ProductCatalogFindSimilarProductsReponseV1,
  ProductCatalogGetMatchingProductsResponseV1,
  ProductCatalogHistoryInterval,
  ProductCatalogHistoryResponse
} from '../../domainTypes/productCatalog';
import { useDeepEqual } from '../../hooks/useDeepEqual';
import { usePromise } from '../../hooks/usePromise';
import { callFirebaseFunction } from '../../services/firebaseFunctions';

export const productCatalogFindSimilarProducts = (
  urls: string[],
  opts: {
    alwaysIncludeSimilarMatches?: boolean;
  } = {}
): Promise<{
  time: number;
  data: ProductCatalogFindSimilarProductsReponseV1[];
}> => {
  return callFirebaseFunction<{
    time: number;
    data: ProductCatalogFindSimilarProductsReponseV1[];
  }>('productCatalog-findSimilarProducts', {
    urls,
    opts: {
      alwaysIncludeSimilarMatches: opts.alwaysIncludeSimilarMatches ?? true
    }
  });
};

export const _productCatalogGetItems = async (
  uids: string[]
): Promise<IProductCatalogRowV1[]> => {
  const res = await callFirebaseFunction<{
    items: IProductCatalogRowV1[];
  }>('productCatalog-getProductCatalogItems', {
    uids
  });
  return res.items;
};

let PRODUCT_CATALOG_ITEMS_CACHE: {
  [uid: string]: Promise<IProductCatalogRowV1 | null>;
} = {};

export type ProductCatalogGetItemsOptions = {
  noCache?: boolean;
};

export const productCatalogGetItemsCacheFlush = () =>
  (PRODUCT_CATALOG_ITEMS_CACHE = {});

export const productCatalogGetItems = async (
  uids: string[],
  opts: ProductCatalogGetItemsOptions = {}
): Promise<IProductCatalogRowV1[]> => {
  const remainingItems = opts.noCache
    ? uids
    : uids.filter((uid) => PRODUCT_CATALOG_ITEMS_CACHE[uid] === undefined);
  if (remainingItems.length) {
    const newItemsPromise = _productCatalogGetItems(remainingItems);
    remainingItems.forEach((uid) => {
      PRODUCT_CATALOG_ITEMS_CACHE[uid] = newItemsPromise.then(
        (ps) => ps.find((p) => p.uid === uid) || null
      );
    });
  }
  return Promise.all(uids.map((uid) => PRODUCT_CATALOG_ITEMS_CACHE[uid])).then(
    compact
  );
};

export const productCatalogGetItem = async (
  uid: string,
  opts: ProductCatalogGetItemsOptions = {}
): Promise<IProductCatalogRowV1 | null> => {
  const items = await productCatalogGetItems([uid], opts);
  return items[0] || null;
};

export const useProductCatalogItem = (uid: string) => {
  return usePromise(() => productCatalogGetItem(uid), [uid]);
};

export const useProductCatalogItems = (uids: string[]) => {
  return usePromise(() => productCatalogGetItems(uids), [uids]);
};

let PRODUCT_CATALOG_GET_HISTORY_CACHE: {
  [key: string]: Promise<ProductCatalogHistoryResponse>;
} = {};

export const productCatalogGetHistoryCacheFlush = () =>
  (PRODUCT_CATALOG_GET_HISTORY_CACHE = {});

const _productCatalogGetHistory = async (
  uid: string,
  range: ISOTimeRange,
  interval: ProductCatalogHistoryInterval
) => {
  const res = await callFirebaseFunction<ProductCatalogHistoryResponse>(
    'productCatalog-getProductCatalogHistory',
    {
      pc_uid: uid,
      range,
      interval
    }
  );
  return res;
};

export const productCatalogGetHistory = async (
  uid: string,
  range: ISOTimeRange,
  interval: ProductCatalogHistoryInterval
) => {
  const key = [
    uid,
    range.start,
    range.end,
    interval.tz,
    interval.unit,
    interval.value
  ]
    .map((x) => x.toString())
    .join('---');
  return (PRODUCT_CATALOG_GET_HISTORY_CACHE[key] =
    PRODUCT_CATALOG_GET_HISTORY_CACHE[key] ||
    _productCatalogGetHistory(uid, range, interval));
};

export const useProductCatalogHistoryDefaultRangeAndInterval = (
  tz: string
): { range: ISOTimeRange; interval: ProductCatalogHistoryInterval } => {
  return useMemo(() => {
    const end = moment().tz(tz).startOf('d').add(1, 'day');
    const start = end.clone().subtract('30', 'days');
    return {
      range: {
        start: start.toISOString(),
        end: end.toISOString()
      },
      interval: {
        unit: 'day',
        value: 1,
        tz
      }
    };
  }, [tz]);
};

export const useProductCatalogHistory = (
  uid: string,
  range: ISOTimeRange,
  interval: ProductCatalogHistoryInterval
) => {
  return usePromise(() => productCatalogGetHistory(uid, range, interval), [
    uid,
    useDeepEqual(range),
    useDeepEqual(interval)
  ]);
};

export const useProductCatalogHistoryWithDefaults = (
  uid: string,
  tz: string
) => {
  const { range, interval } = useProductCatalogHistoryDefaultRangeAndInterval(
    tz
  );
  return usePromise(() => productCatalogGetHistory(uid, range, interval), [
    uid,
    useDeepEqual(range),
    useDeepEqual(interval)
  ]);
};

export const _productCatalogGetMatchingProductsByIds = (uids: string[]) => {
  return callFirebaseFunction<{
    time: number;
    data: ProductCatalogGetMatchingProductsResponseV1[];
  }>('productCatalog-getMatchingProducts', {
    uids,
    opts: {
      includeSimilarityMatches: false
    }
  });
};

let PRODUCT_CATALOG_GET_MATCHING_PRODUCTS_CACHE: {
  [uid: string]: ProductCatalogGetMatchingProductsResponseV1;
} = {};

export const productCatalogGetMatchingProductsCache = () =>
  (PRODUCT_CATALOG_GET_MATCHING_PRODUCTS_CACHE = {});

export const productCatalogGetMatchingProductsById = async (uid: string) => {
  const cached = PRODUCT_CATALOG_GET_MATCHING_PRODUCTS_CACHE[uid];
  if (!cached) {
    const res = await _productCatalogGetMatchingProductsByIds([uid]);
    PRODUCT_CATALOG_GET_MATCHING_PRODUCTS_CACHE[uid] = res.data[0];
  }
  return PRODUCT_CATALOG_GET_MATCHING_PRODUCTS_CACHE[uid];
};

export const useProductCatalogMatchingProductsById = (uid: string) => {
  return usePromise(() => productCatalogGetMatchingProductsById(uid), [uid]);
};
