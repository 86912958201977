import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

export const useDeepEqual = <T>(x: T) => {
  const [state, setState] = useState(x);
  useEffect(() => {
    if (!isEqual(state, x)) {
      setState(x);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [x]);
  return state;
};
