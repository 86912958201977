import { Card, Tooltip, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useMemo } from 'react';
import { Info } from 'react-feather';
import { AlertBox } from '../../../../components/AlertBox';
import { useTimeframe } from '../../../../components/analytics_v2/Timeframe';
import {
  formatChartCurrency,
  formatChartNumber
} from '../../../../components/Charts/Util';
import {
  AnalyticsFilter,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison
} from '../../../../domainTypes/analytics_v2';
import { styled } from '../../../../emotion';
import { useChannelIdGrouper } from '../../../../services/analyticsV2/groups';
import {
  toAnalyticsV2Search,
  useAnalyticsQueryV2
} from '../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../services/currentUser';
import { toLinkSearch, toProductLinkSearch, useLinksFilters } from './service';

const TotalsGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;

  // Handle mobile view using mui breakpoints
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const TotalsGridItem = styled(Card)`
  display: grid;
  align-items: center;
`;

const LoadingCell = () => {
  return (
    <TotalsGridItem>
      <Typography variant="body2">
        <Skeleton variant="text" width={100} height={20} />
        <Skeleton variant="text" width={72} height={32} />
      </Typography>
    </TotalsGridItem>
  );
};

const OverallStockStatsCell = ({
  row,
  loading
}: {
  row: AnalyticsResponseRowWithComparison | null;
  loading: boolean;
}) => {
  if (loading) {
    return (
      <>
        <LoadingCell />
        <LoadingCell />
        <LoadingCell />
      </>
    );
  }

  const linkCount = row?.data.count_uniq_link_id?.curr ?? 0;

  const pageviews = row?.data.p?.curr ?? 0;

  return (
    <>
      <TotalsGridItem>
        <Typography variant="body2" component="p" color="textSecondary">
          Links affected
        </Typography>
        <Typography variant="h5" component="p">
          {formatChartNumber(linkCount)}
        </Typography>
      </TotalsGridItem>
      <TotalsGridItem>
        <Typography variant="body2" component="p" color="textSecondary">
          Pageviews affected
        </Typography>
        <Typography variant="h5" component="p">
          {formatChartNumber(pageviews)}
        </Typography>
      </TotalsGridItem>
    </>
  );
};

const MissingEarningsCell = ({
  row,
  loading
}: {
  row: AnalyticsResponseRowWithComparison | null;
  loading: boolean;
}) => {
  const { space } = useCurrentUser();
  const currency = space.config.currency;
  const { range, compare } = useTimeframe();
  const { columnTransformers } = useChannelIdGrouper();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      select: ['commission_sum_net', 'gmv_sum_net'],
      range,
      compare,
      filters: [
        {
          field: 'pk',
          condition: 'in',
          values: ['amazon']
        }
      ],
      columnTransformers: columnTransformers(space)
    };
  }, [range, columnTransformers, space, compare]);
  const [data, loading2, error] = useAnalyticsQueryV2(space.id, query, {
    logMode: 'full',
    logLabel: 'TotalsRow'
  });
  const query2 = useMemo<AnalyticsQuery>(() => {
    return {
      select: ['c'],
      range,
      compare,
      search: toAnalyticsV2Search('amazon', ['link_url']),
      columnTransformers: columnTransformers(space)
    };
  }, [range, columnTransformers, space, compare]);
  const [data2, loading3, error2] = useAnalyticsQueryV2(space.id, query2, {
    logMode: 'full',
    logLabel: 'TotalsRow'
  });

  if (error || error2) {
    console.log('error', error);
    console.log('error2', error2);
    return <AlertBox variant="error">Error loading totals</AlertBox>;
  }

  if (loading || loading2 || loading3) {
    return (
      <>
        <LoadingCell />
        <LoadingCell />
      </>
    );
  }

  if (!data || !data.rows || !data2 || !data2.rows) {
    return (
      <TotalsGridItem>
        <Typography variant="body2">
          There are no Amazon commissions.
        </Typography>
      </TotalsGridItem>
    );
  }

  const allAmazonCommissions =
    data?.rows[0]?.data?.commission_sum_net?.curr ?? 0;
  const amazonClicks = data2?.rows[0]?.data?.c?.curr ?? 0;
  const linksWithIssuesClicks = row?.data?.c?.curr ?? 0;
  const amazonEpc = allAmazonCommissions / amazonClicks;
  const badClicks = row?.data.c?.curr ?? 0;
  const percentBadClicks = (linksWithIssuesClicks / amazonClicks) * 100;

  return (
    <>
      <TotalsGridItem>
        <Typography variant="body2" component="p" color="textSecondary">
          Lost clicks
        </Typography>
        <Typography variant="h5" component="p">
          {formatChartNumber(badClicks)}
        </Typography>
      </TotalsGridItem>
      <TotalsGridItem>
        <Typography variant="body2" component="p" color="textSecondary">
          % Clicks affected
        </Typography>
        <Typography variant="h5" component="p">
          {percentBadClicks.toFixed(2)}%
        </Typography>
      </TotalsGridItem>
      <TotalsGridItem>
        <Typography variant="body2" component="p" color="textSecondary">
          Estimated loss{' '}
          <Tooltip
            title="Based on your typical Amazon EPC (earnings per click)."
            placement="top"
          >
            <Info size={12} />
          </Tooltip>
        </Typography>
        <Typography variant="h5" component="p">
          <strong>
            -{formatChartCurrency(amazonEpc * linksWithIssuesClicks, currency)}
          </strong>
        </Typography>
      </TotalsGridItem>
    </>
  );
};

export const StockStats = ({
  linkSearch,
  mode,
  additionalFilters
}: {
  linkSearch: string;
  mode: 'links' | 'products';
  additionalFilters?: AnalyticsFilter[];
}) => {
  const { space } = useCurrentUser();
  const filters = useLinksFilters(additionalFilters);
  const { range, compare } = useTimeframe();
  const { columnTransformers } = useChannelIdGrouper();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      select: ['count_uniq_link_id', 'c', 'p'],
      range,
      compare,
      filters,
      search:
        mode === 'links'
          ? toLinkSearch(linkSearch)
          : toProductLinkSearch(linkSearch),
      columnTransformers: columnTransformers(space)
    };
  }, [range, filters, columnTransformers, space, linkSearch, compare, mode]);
  const [data, loading, error] = useAnalyticsQueryV2(space.id, query, {
    logMode: 'full',
    logLabel: 'StockStats'
  });

  if (error) {
    return <AlertBox variant="error">Error loading totals</AlertBox>;
  }

  return (
    <TotalsGrid>
      <OverallStockStatsCell
        row={data ? data.rows[0] : null}
        loading={loading}
      />
      <MissingEarningsCell row={data ? data.rows[0] : null} loading={loading} />
    </TotalsGrid>
  );
};
