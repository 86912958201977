import { CampaignTeamFilterDefinition } from '../../../../../domainTypes/filters';
import React, { useCallback, useMemo, useState } from 'react';
import { BASIC_MODES, FilterMenu } from '../FilterMenu';
import { useCurrentUser } from '../../../../../services/currentUser';
import { useTeamsCollection } from '../../../../../features/Campaigns/service/teams';
import { useMappedLoadingValue } from '../../../../../services/db';
import {
  OptionsList,
  SelectorLoader,
  SelectorShell,
  toggle
} from '../Selector';
import {
  createCampaignTeamFilterDefinition,
  initFilterValue,
  validateFilterDefinition
} from '../../filters';
import { ITeam } from '../../../../../domainTypes/teams';
import pluralize from 'pluralize';
import { isEqual } from 'lodash';
import { WithShape } from '../../../../Charts/Util';
import { getStableRandomColor } from '../../../../../services/color';
import { toSearchRegexp } from '../../../../SearchInput';

const CampaignTeamPicker: React.FC<{
  onSave: (definition: CampaignTeamFilterDefinition) => void;
  teams: ITeam[];
  definition: CampaignTeamFilterDefinition;
}> = ({ definition, teams, onSave }) => {
  const [value, setValue] = useState(definition.v);
  const newDefinition = useMemo<CampaignTeamFilterDefinition>(
    () => createCampaignTeamFilterDefinition(value),
    [value]
  );
  const enableSave =
    validateFilterDefinition(newDefinition) &&
    !isEqual(definition, newDefinition);

  const [search, setSearch] = useState('');

  const options = useMemo(() => {
    const searchRe = toSearchRegexp(search);
    return teams
      .filter((team) => !searchRe || team.name.toLowerCase().match(searchRe))
      .map((team) => ({
        value: team.teamId,
        label: (
          <WithShape color={getStableRandomColor(team.teamId)}>
            {team.name}
          </WithShape>
        )
      }));
  }, [search, teams]);

  const _toggle = useCallback(
    (teamId: string) => {
      setValue(toggle(value, teamId));
    },
    [value]
  );

  const focus = useCallback(
    (teamId: string) => {
      onSave(createCampaignTeamFilterDefinition([teamId]));
    },
    [onSave]
  );

  return (
    <>
      <FilterMenu.Body>
        <SelectorShell label="Teams" search={search} setSearch={setSearch}>
          <OptionsList
            options={options}
            selectedValues={value}
            toggle={_toggle}
            focus={focus}
          />
        </SelectorShell>
      </FilterMenu.Body>
      <FilterMenu.Footer definition={definition}>
        <FilterMenu.SaveButton
          disabled={!enableSave}
          onSave={() => onSave(newDefinition)}
          label={`Filter by ${pluralize('team', value.length, true)}`}
        />
      </FilterMenu.Footer>
    </>
  );
};

const InnerCampaignTeamMenu = (props: {
  onSave: (definition: CampaignTeamFilterDefinition) => void;
  definition: CampaignTeamFilterDefinition;
}) => {
  const { space } = useCurrentUser();
  const [teams, loading] = useMappedLoadingValue(
    useTeamsCollection(space.id),
    (teams) => teams.map((team) => team.data)
  );

  if (!teams || loading) {
    return (
      <>
        <FilterMenu.Body>
          <SelectorLoader />
        </FilterMenu.Body>
        <FilterMenu.Footer definition={initFilterValue('campaign_team')}>
          <FilterMenu.SaveButton
            disabled
            onSave={() => {}}
            label="Loading teams..."
          />
        </FilterMenu.Footer>
      </>
    );
  }

  return <CampaignTeamPicker {...props} teams={teams} />;
};

export const CampaignTeamMenu: React.FC<{
  definition: CampaignTeamFilterDefinition;
  onSave: (definition: CampaignTeamFilterDefinition) => void;
}> = ({ definition, onSave }) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);

  return (
    <FilterMenu>
      <FilterMenu.Header dimension="campaign_team">
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <InnerCampaignTeamMenu definition={definition} onSave={onSave} />
    </FilterMenu>
  );
};
