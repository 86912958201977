import React, { useMemo } from 'react';
import { CampaignGroupBy, CampaignsGroupMetrics } from '../../service/groups';
import { Manager } from './Managers';
import Typography from '@material-ui/core/Typography';
import { CampaignStatus, campaignStatusTitle } from '../../service';
import {
  CampaignListColumnDefinition,
  CAMPAIGN_GROUP_HEADER_COLUMNS,
  CampaignGroupHeaderColumnDefinition
} from '../../service/columns';
import { useCurrentUser } from '../../../../services/currentUser';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import { styled } from '../../../../emotion';
import { FlexContainer } from '../../../../layout/Flex';
import { CampaignStatusIcon } from '../CampaignStatusIcon';
import { TeamWithColor } from '../teams/TeamWithColor';
import { isEmpty } from 'lodash';

export const CampaignsGroupTitle: React.FC<{
  group: CampaignGroupBy;
  value: string;
}> = ({ group, value }) => {
  switch (group) {
    case 'team':
      return isEmpty(value) ? (
        <Typography variant="body1">No team</Typography>
      ) : (
        <TeamWithColor teamId={value} />
      );
    case 'manager':
      return <Manager userId={value} />;
    case 'status':
      return (
        <FlexContainer alignItems="center">
          <CampaignStatusIcon status={value as CampaignStatus} />
          <Typography variant="body1">
            {campaignStatusTitle(value as CampaignStatus)}
          </Typography>
        </FlexContainer>
      );
    case 'start-month':
    case 'end-month':
    case 'start-year':
    case 'end-year':
    case 'start-quarter':
    case 'end-quarter':
      return <Typography variant="body1">{value}</Typography>;
  }
};

const GroupMetricsHeaderWrapper = styled('div')`
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(p) => p.theme.spacing(4)}px;
  color: ${(p) => p.theme.palette.text.secondary};

  > div {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${(p) => p.theme.spacing(2)}px;
  }
`;

export const CampaignsGroupMetricsHeader: React.FC<{
  metrics: CampaignsGroupMetrics;
  columns: CampaignListColumnDefinition[];
}> = ({ metrics, columns }) => {
  const { space } = useCurrentUser();
  const currency = useSpaceCurrency();
  const otherProps = useMemo(
    () => ({
      spaceId: space.id,
      currency,
      tz: space.config.tz
    }),
    [currency, space.config.tz, space.id]
  );

  return (
    <GroupMetricsHeaderWrapper>
      <Typography variant="caption" component="p" color="textSecondary">
        Campaigns
      </Typography>
      <strong>{metrics.count}</strong>
      {CAMPAIGN_GROUP_HEADER_COLUMNS.filter((c) =>
        columns.some((col) => col.key === c.key)
      ).map((column: CampaignGroupHeaderColumnDefinition) => {
        return (
          <>
            <Typography key={column.key} variant="caption" noWrap>
              {column.head}
            </Typography>
            <strong style={{ width: column.width, textAlign: 'right' }}>
              {column.cell(metrics, otherProps)}
            </strong>
          </>
        );
      })}
    </GroupMetricsHeaderWrapper>
  );
};
