import React from 'react';
import {
  PageBody,
  SideNavHowTo,
  SideNavProps
} from '../../../../layout/PageBody';
import { useRoutes } from '../../../../routes';

export const PageBodyInventory: React.FC<{
  howTo?: SideNavHowTo;
  noTopPadding?: boolean;
}> = ({ howTo, noTopPadding, children }) => {
  const { ROUTES } = useRoutes();

  const sideNav: SideNavProps = {
    groups: [
      {
        label: 'Inventory',
        items: [
          {
            label: 'Issues',
            path: ROUTES.inventory.issues.url()
          }
        ]
      }
    ],
    howTo: howTo
  };
  return (
    <>
      <PageBody sideNav={sideNav} noTopPadding={noTopPadding}>
        {children}
      </PageBody>
    </>
  );
};
