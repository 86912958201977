import React from 'react';
import { useTeamsCollection } from '../../service/teams';
import { useCurrentUser } from '../../../../services/currentUser';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select
} from '@material-ui/core';
import { WithShape } from '../../../../components/Charts/Util';
import { getStableRandomColor } from '../../../../services/color';

export const TeamSelector: React.FC<{
  value: string;
  onChange: (team: string) => void;
  onFocus: () => void;
  onBlur: () => void;
  error?: boolean;
  helperText?: string;
}> = ({ value, onChange, onBlur, onFocus, error, helperText }) => {
  const { space } = useCurrentUser();
  const [teams = [], loading] = useTeamsCollection(space.id);
  return (
    <FormControl error={error} fullWidth>
      <Select
        value={value}
        onChange={(e) => {
          onChange(e.target.value as string);
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        variant="outlined"
        fullWidth
        disabled={loading}
      >
        {teams.map((team) => (
          <MenuItem key={team.data.teamId} value={team.data.teamId}>
            <WithShape color={getStableRandomColor(team.data.teamId)} large>
              {team.data.name}
            </WithShape>
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
