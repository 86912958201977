import { IColumn } from '../../../components/Table/Column';
import { CurrencyCode } from '../../../domainTypes/currency';
import Tooltip from '@material-ui/core/Tooltip';
import { AdvertiserWithColor } from '../../../components/AdvertiserWithColor';
import { prettifyDateRange } from '../../../services/time';
import { FlexContainer } from '../../../layout/Flex';
import { CampaignStatusIcon } from '../components/CampaignStatusIcon';
import { campaignStatusTitle, getFlatSpendAmount } from './index';
import { CampaignRateIncreaseIncentive } from '../../../domainTypes/campaigns';
import React, { ReactNode } from 'react';
import { Managers } from '../components/list/Managers';
import {
  CampaignData,
  CampaignGoal
} from '../components/list/useCampaignsData';
import { CountCell } from '../../../components/Table';
import { metricTitle } from '../../../services/analyticsV2/metrics';
import { interpose } from '../../../services/interpose';
import { compact } from 'lodash';
import { Target } from 'react-feather';
import { COLORS } from '../../../domainTypes/colors';
import Typography from '@material-ui/core/Typography';
import { formatNumber } from '../../../components/Number';
import { WithHoverIndicator } from '../../../components/WithHoverIndicator';
import { Dash } from '../../../components/Table/CountCell';
import { CampaignsGroupMetrics } from './groups';
import { TeamWithColor } from '../components/teams/TeamWithColor';

export const campaignsGroupHeaderColumns = [
  'flatSpend',
  'gmv',
  'clicks',
  'impressions',
  'pageviews',
  'orders'
] as const;

export type CampaignListColumn =
  | 'name'
  | 'advertisers'
  | 'status'
  | 'timeframe'
  | 'team'
  | 'manager'
  | 'flatSpend'
  | 'rateIncrease'
  | 'goalStatus'
  | 'goalReached'
  | 'gmv'
  | 'clicks'
  | 'impressions'
  | 'pageviews'
  | 'aov'
  | 'orders'
  | 'conversionRate'
  | 'ctr';

export const defaultColumnsCampaigns: CampaignListColumn[] = [
  'name',
  'manager',
  'timeframe',
  'advertisers',
  'flatSpend',
  'gmv',
  'clicks'
];

interface CampaignCellContext {
  spaceId: string;
  currency: CurrencyCode;
  tz: string;
}

export type CampaignListColumnDefinition = IColumn<
  CampaignData,
  CampaignListColumn,
  CampaignCellContext
>;

type HeaderColumn = typeof campaignsGroupHeaderColumns[number];

export interface CampaignGroupHeaderColumnDefinition {
  key: HeaderColumn;
  width: number;
  head: ReactNode;
  cell: (d: CampaignsGroupMetrics, p: CampaignCellContext) => ReactNode;
}

export const CAMPAIGN_GROUP_HEADER_COLUMNS: Array<CampaignGroupHeaderColumnDefinition> = [
  {
    key: 'flatSpend',
    width: 80,
    head: 'Flat spend',
    cell: (metrics, { currency }) => currencyCell(metrics.flatSpend, currency)
  },
  {
    key: 'gmv',
    width: 80,
    head: metricTitle('gmv_sum_net'),
    cell: (metrics, { currency }) => currencyCell(metrics.gmv, currency)
  },
  {
    key: 'clicks',
    width: 50,
    head: metricTitle('c'),
    cell: (metrics) => numberCell(metrics.clicks)
  },
  {
    key: 'impressions',
    width: 80,
    head: metricTitle('v'),
    cell: (metrics) => numberCell(metrics.impressions)
  },
  {
    key: 'pageviews',
    width: 80,
    head: metricTitle('p'),
    cell: (metrics) => numberCell(metrics.pageviews)
  },
  {
    key: 'orders',
    width: 50,
    head: metricTitle('order_count_net'),
    cell: (metrics) => numberCell(metrics.orders)
  }
];

const numberCell = (value: number | undefined) => (
  <CountCell before={0} after={value ?? 0} compare={false} dashWhenAllZero />
);

const percentCell = (value: number | undefined, decimalDigits = 0) => (
  <CountCell
    before={0}
    after={value ?? 0}
    compare={false}
    format="percent"
    digits={decimalDigits}
    dashWhenAllZero
  />
);

const currencyCell = (value: number | undefined, currency: CurrencyCode) => (
  <CountCell
    before={0}
    after={value ?? 0}
    compare={false}
    currency={currency}
    dashWhenAllZero
  />
);

const goalColor = (goal: CampaignGoal) => {
  switch (goal.status) {
    case 'onTrack':
      return COLORS.blue.blue5;
    case 'behind':
      return COLORS.gold.gold6;
    case 'pending':
      return '#eee';
  }
};

const goalStatusCell = (goal: CampaignGoal | undefined, label: string) => {
  if (!goal) return null;
  return (
    <>
      <Typography variant="caption" color="textSecondary">
        {label}:
      </Typography>
      <Target size={16} color={goalColor(goal)} />
    </>
  );
};

const goalReachedCell = (goal: CampaignGoal | undefined, label: string) => {
  if (!goal) return null;
  return (
    <>
      <Typography variant="caption" color="textSecondary">
        {label}:
      </Typography>
      <Typography variant="caption" color="textPrimary">
        {goal.status === 'pending' ? (
          <Dash size={12} />
        ) : (
          formatNumber({ n: goal.percentage, format: 'percent', digits: 2 })
        )}
      </Typography>
    </>
  );
};

export const CAMPAIGN_LIST_COLUMN_GROUPS: Array<{
  label: string;
  columns: CampaignListColumnDefinition[];
}> = [
  {
    label: 'Campaign details',
    columns: [
      {
        key: 'name',
        head: () => 'Name',
        align: 'left',
        cell: (row) => (
          <WithHoverIndicator>{row.campaign.name}</WithHoverIndicator>
        ),
        width: 200
      },
      {
        key: 'advertisers',
        head: () => 'Advertisers',
        align: 'left',
        cell: (row) => (
          <Tooltip
            title={row.campaign.advertisers.map((a) => a.name).join(', ')}
          >
            <div>
              {row.campaign.advertisers.map((advertiser) => (
                <AdvertiserWithColor
                  key={advertiser.advertiserId}
                  advertiserName={advertiser.name}
                  partnerKey={advertiser.partnerKey}
                />
              ))}
            </div>
          </Tooltip>
        ),
        width: 200
      },
      {
        key: 'timeframe',
        head: () => 'Timeframe',
        align: 'left',
        cell: (row, otherProps) =>
          row.campaign.timeframe
            ? prettifyDateRange(
                row.campaign.timeframe.start,
                row.campaign.timeframe.end,
                otherProps.tz
              )
            : 'Not set',
        width: 150
      },
      {
        key: 'status',
        head: () => 'Status',
        align: 'left',
        width: 120,
        cell: (row) => (
          <FlexContainer>
            <CampaignStatusIcon status={row.campaign.status} />
            <span>{campaignStatusTitle(row.campaign.status)}</span>
          </FlexContainer>
        )
      },
      {
        key: 'team',
        head: () => 'Team',
        align: 'left',
        cell: (row) => <TeamWithColor teamId={row.campaign.team} />,
        width: 150
      },
      {
        key: 'manager',
        head: () => 'Managers',
        align: 'center',
        cell: (row) => <Managers userIds={row.campaign.managers} />,
        width: 50
      }
    ]
  },
  {
    label: 'Incentives',
    columns: [
      {
        key: 'flatSpend',
        head: () => 'Flat spend',
        align: 'right',
        cell: (row, { currency }) =>
          currencyCell(getFlatSpendAmount(row.campaign), currency),
        width: 100,
        flexGrow: 2
      },
      {
        key: 'rateIncrease',
        head: () => 'Rate increase',
        align: 'right',
        cell: (row) => {
          const rateIncrease = row.campaign.incentives.find(
            (g) => g.type === 'rateIncrease'
          ) as CampaignRateIncreaseIncentive;
          if (!rateIncrease) return <Dash size={12} />;
          return `From ${rateIncrease.from} to ${rateIncrease.to}`;
        },
        width: 100,
        flexGrow: 2
      }
    ]
  },
  {
    label: 'Metrics',
    columns: [
      {
        key: 'gmv',
        head: () => metricTitle('gmv_sum_net'),
        align: 'right',
        cell: (row, { currency }) => currencyCell(row.totals?.gmv, currency),
        width: 120,
        flexGrow: 2
      },
      {
        key: 'ctr',
        head: () => metricTitle('ctr'),
        align: 'right',
        cell: (row) => percentCell(row.totals?.ctr),
        width: 50,
        flexGrow: 2
      },
      {
        key: 'clicks',
        head: () => metricTitle('c'),
        align: 'right',
        cell: (row) => numberCell(row.totals?.clicks),
        width: 50,
        flexGrow: 2
      },
      {
        key: 'impressions',
        head: () => metricTitle('v'),
        align: 'right',
        cell: (row) => numberCell(row.totals?.impressions),
        width: 100,
        flexGrow: 2
      },
      {
        key: 'pageviews',
        head: () => metricTitle('p'),
        align: 'right',
        cell: (row) => numberCell(row.totals?.pageviews),
        width: 100,
        flexGrow: 2
      },
      {
        key: 'orders',
        head: () => metricTitle('order_count_net'),
        align: 'right',
        cell: (row) => numberCell(row.totals?.orders),
        width: 50,
        flexGrow: 2
      },
      {
        key: 'aov',
        head: () => metricTitle('aov_net'),
        align: 'right',
        cell: (row, { currency }) => currencyCell(row.totals?.aov, currency),
        width: 100,
        flexGrow: 2
      },
      {
        key: 'conversionRate',
        head: () => 'Conversion rate',
        align: 'right',
        cell: (row) => percentCell(row.totals?.conversionRate, 1),
        width: 100,
        flexGrow: 2
      }
    ]
  },
  {
    label: 'Goals',
    columns: [
      {
        key: 'goalStatus',
        head: () => 'Goal status',
        align: 'center',
        cell: (row) => {
          const goals = compact([
            goalStatusCell(row.goals.gmv, 'GMV'),
            goalStatusCell(row.goals.clicks, 'Clicks'),
            goalStatusCell(row.goals.pageviews, 'Pageviews')
          ]);
          return (
            <FlexContainer justifyContent="center">
              {interpose(
                goals,
                <Typography variant="caption" color="textSecondary">
                  /
                </Typography>
              )}
            </FlexContainer>
          );
        },
        width: 200,
        flexGrow: 2
      },
      {
        key: 'goalReached',
        head: () => 'Goal reached',
        align: 'center',
        cell: (row) => {
          const goals = compact([
            goalReachedCell(row.goals.gmv, 'GMV'),
            goalReachedCell(row.goals.clicks, 'Clicks'),
            goalReachedCell(row.goals.pageviews, 'Pageviews')
          ]);
          return (
            <FlexContainer justifyContent="center">
              {interpose(
                goals,
                <Typography variant="caption" color="textSecondary">
                  /
                </Typography>
              )}
            </FlexContainer>
          );
        },
        width: 200,
        flexGrow: 2
      }
    ]
  }
];

export const CAMPAIGN_COLUMNS: CampaignListColumnDefinition[] = CAMPAIGN_LIST_COLUMN_GROUPS.flatMap(
  (g) => g.columns
);
