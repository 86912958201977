import React from 'react';
import { Helmet } from 'react-helmet';
import { NoPermissions } from '../../../../components/NoPermissions';
import { useHasCurrentUserRequiredScopes } from '../../../../services/currentUser';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { PerformancePageBody } from '../../components/PerformancePageBody';
import { PagePerformanceChannelsContentV2 } from './PagePerformanceChannelsContentV2';

export const PagePerformanceChannels = () => {
  useTrackMixpanelView('view_channels');
  const [canView] = useHasCurrentUserRequiredScopes(['reports.channels.view']);

  return (
    <>
      <Helmet>
        <title>Channels | Affilimate</title>
      </Helmet>
      {canView ? (
        <PagePerformanceChannelsContentV2 />
      ) : (
        <PerformancePageBody>
          <NoPermissions />
        </PerformancePageBody>
      )}
    </>
  );
};
