import { ITeam } from '../../../../domainTypes/teams';
import React, { ReactNode } from 'react';
import { Button, InputLabel, Paper, TextField } from '@material-ui/core';
import { css, styled } from '../../../../emotion';
import { Field, Form } from 'react-final-form';
import { required } from '../../../../components/Form/validators';
import { AutocompleteUserMultiById } from '../../../../components/AutocompleteUserMulti';
import { FlexContainer } from '../../../../layout/Flex';
import { ForecastsField } from './ForecastsField';
import { WithShape } from '../../../../components/Charts/Util';
import { getStableRandomColor } from '../../../../services/color';

const FieldContainer = styled('div')((p) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: p.theme.spacing(2)
}));

interface TeamCardProps {
  team: ITeam;
  deleteButton: ReactNode;
  showReset?: boolean;
  autoFocus?: boolean;
  saveLabel: string;
  saveTeam: (team: ITeam) => Promise<void>;
  canModify: boolean;
}

export const TeamCard: React.FC<TeamCardProps> = ({
  team,
  deleteButton,
  showReset,
  saveLabel,
  saveTeam,
  canModify,
  autoFocus
}) => {
  return (
    <Paper
      className={css((t) => ({
        padding: t.spacing(2)
      }))}
    >
      <Form
        onSubmit={async (d) => {
          await saveTeam({
            ...team,
            name: d.name,
            users: d.users,
            forecasts: d.forecasts
          });
        }}
        initialValues={{
          name: team.name ?? '',
          users: team.users ?? [],
          forecasts: team.forecasts ?? []
        }}
        render={({ handleSubmit, submitting, form, modified }) => {
          const formModified =
            modified && Object.values(modified).some((v) => v);
          return (
            <form onSubmit={handleSubmit}>
              <div
                className={css((t) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  gap: t.spacing(2)
                }))}
              >
                <FieldContainer>
                  <FlexContainer justifyContent="space-between">
                    <InputLabel>Team name</InputLabel>
                    {deleteButton}
                  </FlexContainer>
                  <Field name="name" validate={required}>
                    {({ input, meta }) => {
                      const hasError = meta.error && meta.touched;
                      return (
                        <TextField
                          {...input}
                          error={meta.error && meta.touched}
                          helperText={
                            hasError && 'Please provide a name for the team'
                          }
                          variant="outlined"
                          fullWidth
                          required
                          autoFocus={autoFocus}
                          InputProps={{
                            readOnly: !canModify,
                            startAdornment: (
                              <WithShape
                                color={getStableRandomColor(team.teamId)}
                                large
                              />
                            )
                          }}
                        />
                      );
                    }}
                  </Field>
                </FieldContainer>
                <FieldContainer>
                  <InputLabel>Managers</InputLabel>
                  <Field name="users">
                    {({ input }) => (
                      <AutocompleteUserMultiById
                        spaceId={team.spaceId}
                        value={input.value}
                        onChange={input.onChange}
                        fullWidth
                        readOnly={!canModify}
                      />
                    )}
                  </Field>
                </FieldContainer>
                <FieldContainer>
                  <Field name="forecasts">
                    {({ input }) => (
                      <ForecastsField
                        value={input.value}
                        onChange={input.onChange}
                        readOnly={!canModify}
                      />
                    )}
                  </Field>
                </FieldContainer>

                <FlexContainer>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={!canModify || !formModified || submitting}
                  >
                    {saveLabel}
                  </Button>
                  {showReset ? (
                    <Button
                      onClick={() => form.reset()}
                      color="primary"
                      disabled={!formModified}
                    >
                      Reset
                    </Button>
                  ) : null}
                </FlexContainer>
              </div>
            </form>
          );
        }}
      />
    </Paper>
  );
};
