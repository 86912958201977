import React, { useCallback, useState } from 'react';
import { Button } from '@material-ui/core';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../services/currentUser';
import { CampaignsPageBody } from '../components/CampaignsPageBody';
import { styled } from '../../../emotion';
import { Helmet } from 'react-helmet';
import { NoPermissions } from '../../../components/NoPermissions';
import { useTeamsCollection } from '../service/teams';
import Typography from '@material-ui/core/Typography';
import { Loader } from '../../../components/Loader';
import { FlexContainer } from '../../../layout/Flex';
import { ITeam } from '../../../domainTypes/teams';
import { Doc } from '../../../domainTypes/document';
import shortid from 'shortid';
import firebase from 'firebase/app';
import { TeamDraft } from '../components/teams/TeamDraft';
import { Team } from '../components/teams/Team';

const PageWrapper = styled('div')((p) => ({
  margin: '0 auto',
  maxWidth: 1200,
  marginTop: p.theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: p.theme.spacing(2)
}));

const ListWrapper = styled('div')((p) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(500px, 1fr))',
  gap: p.theme.spacing(2)
}));

const TeamsBody: React.FC<{
  teams: Array<Doc<ITeam>>;
  drafts: Array<ITeam>;
  deleteDraft: (id: string) => void;
}> = ({ teams, drafts, deleteDraft }) => (
  <ListWrapper>
    {drafts.map((draft) => (
      <TeamDraft key={draft.teamId} team={draft} deleteDraft={deleteDraft} />
    ))}
    {teams
      .filter((t) => !drafts.some((d) => d.teamId === t.data.teamId))
      .map((team) => (
        <Team team={team.data} key={team.data.teamId} />
      ))}
  </ListWrapper>
);

export const CampaignsTeams: React.FC = () => {
  const { space, id } = useCurrentUser();
  const scopes = useCurrentUserScopes();
  const canView = scopes.has('teams.view');
  const canCreate = scopes.has('teams.create');
  const [teams, loading, error] = useTeamsCollection(space.id);
  const [drafts, setDrafts] = useState<ITeam[]>([]);
  const deleteDraft = useCallback((id: string) => {
    setDrafts((drafts) => drafts.filter((d) => d.teamId !== id));
  }, []);
  const addDraft = useCallback(() => {
    setDrafts((drafts) => [
      {
        teamId: shortid(),
        spaceId: space.id,
        name: '',
        users: [],
        forecasts: [],
        createdBy: id,
        createdAt: firebase.firestore.Timestamp.now()
      },
      ...drafts
    ]);
  }, [id, space.id]);

  return (
    <>
      <Helmet>
        <title>Campaigns Teams | Affilimate</title>
      </Helmet>
      <CampaignsPageBody>
        <PageWrapper>
          {!canView ? (
            <NoPermissions />
          ) : (
            <>
              <FlexContainer justifyContent="space-between">
                <Typography component="h1" variant="h6">
                  <strong>Teams</strong>
                </Typography>
                {canCreate ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={addDraft}
                  >
                    Add team
                  </Button>
                ) : null}
              </FlexContainer>

              {error ? (
                <>
                  <Typography variant="h5" component="p" paragraph>
                    <strong>Couldn't load teams</strong>
                  </Typography>
                  <Typography variant="body1" component="p" paragraph>
                    Try refreshing the page. If you expect to see data here and
                    the error persists, please contact Support with the URL to
                    this page.
                  </Typography>
                </>
              ) : loading || !teams ? (
                <Loader size={32} height={180} />
              ) : (
                <TeamsBody
                  teams={teams}
                  drafts={drafts}
                  deleteDraft={deleteDraft}
                />
              )}
            </>
          )}
        </PageWrapper>
      </CampaignsPageBody>
    </>
  );
};
