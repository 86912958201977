import { Campaign } from '../../service';
import { useStringQueryParam } from '../../../../routes';
import { useFiltersParam } from '../../../../components/analytics_v2/Filters/hooks';
import { useCallback } from 'react';
import { overEvery } from 'lodash';
import { FilterDefinition } from '../../../../domainTypes/filters';

export const SEARCH_PARAM_NAME = 'q';

const matchesSearch = (search: string): ((c: Campaign) => boolean) => {
  if (!search) return () => true;
  return (c: Campaign) => c.name.toLowerCase().includes(search);
};

const toPredicate = (def: FilterDefinition): ((c: Campaign) => boolean) => {
  switch (def.k) {
    case 'campaign_status':
      return (c) => def.v.includes(c.status);
    case 'campaign_team':
      return (c) => def.v.includes(c.team);
    case 'campaign_manager':
      return (c) => c.managers.some((m) => def.v.includes(m));
    case 'campaign_advertiser':
      return (c) => c.advertisers.some((a) => def.v.includes(a.advertiserId));
    default:
      return () => true;
  }
};

export const useCampaignsFilter = (): ((c: Campaign[]) => Campaign[]) => {
  const [search] = useStringQueryParam(SEARCH_PARAM_NAME);
  const [filters] = useFiltersParam();
  return useCallback(
    (cs: Campaign[]) =>
      cs.filter((c) =>
        overEvery([matchesSearch(search), ...filters.map(toPredicate)])(c)
      ),
    [filters, search]
  );
};
