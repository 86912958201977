import React, { useCallback, useMemo, useRef, useState } from 'react';
import { IForecast } from '../../../../domainTypes/teams';
import { Stack } from '../form/form-components';
import {
  Button,
  ButtonBase,
  InputLabel,
  Menu,
  MenuItem
} from '@material-ui/core';
import { PlusCircle, XCircle } from 'react-feather';
import moment from 'moment-timezone';
import { range, sum, values } from 'lodash';
import { FlexContainer } from '../../../../layout/Flex';
import { MoneyInput } from '../../../../components/MoneyInput';
import { symbolForCurrencyCode } from '../../../../domainTypes/currency';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';

interface ForecastsFieldProps {
  value: IForecast[];
  onChange: (value: IForecast[]) => void;
  readOnly?: boolean;
}

const QS = ['q1', 'q2', 'q3', 'q4'] as const;

const ForecastRow: React.FC<{
  onChange: (value: IForecast) => void;
  deleteForecast: (year: number) => void;
  forecast: IForecast;
}> = ({ onChange, forecast, deleteForecast }) => {
  const currency = useSpaceCurrency();
  const onAmountChange = useCallback(
    (quarter: typeof QS[number], value: number) => {
      onChange({
        ...forecast,
        amounts: {
          ...forecast.amounts,
          [quarter]: value
        }
      });
    },
    [forecast, onChange]
  );
  return (
    <FlexContainer spacing={2}>
      {QS.map((q) => (
        <MoneyInput
          key={q}
          variant="outlined"
          label={`${q.toUpperCase()} ${forecast.year}`}
          numericFormatProps={{
            prefix: symbolForCurrencyCode(currency)
          }}
          onChange={(value) => onAmountChange(q, value * 100)}
          value={(forecast.amounts[q] ?? 0) / 100}
        />
      ))}
      <MoneyInput
        key={forecast.year}
        value={sum(values(forecast.amounts)) / 100}
        onChange={() => {}}
        disabled
        variant="outlined"
        label={forecast.year}
        numericFormatProps={{
          prefix: symbolForCurrencyCode(currency)
        }}
      />
      <ButtonBase onClick={() => deleteForecast(forecast.year)}>
        <XCircle size={18} />
      </ButtonBase>
    </FlexContainer>
  );
};

export const ForecastsField: React.FC<ForecastsFieldProps> = ({
  value,
  readOnly,
  onChange
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnchor = useRef<HTMLButtonElement | null>(null);
  const options = useMemo(() => {
    const currentYear = moment().year();
    return range(currentYear, currentYear + 5).map((year) => ({
      year,
      disabled: value.some((forecast) => forecast.year === year)
    }));
  }, [value]);
  const addForecast = useCallback(
    (year: number) => {
      onChange([
        ...value,
        {
          kind: 'quarterlyFlatSpend',
          year,
          amounts: {
            q1: null,
            q2: null,
            q3: null,
            q4: null
          }
        }
      ]);
    },
    [onChange, value]
  );
  const deleteForecast = useCallback(
    (year: number) => {
      onChange(value.filter((forecast) => forecast.year !== year));
    },
    [onChange, value]
  );

  return (
    <Stack>
      <InputLabel>
        Forecasts
        <Button
          ref={menuAnchor}
          startIcon={<PlusCircle size={16} />}
          color="primary"
          style={{ marginLeft: 12 }}
          disabled={readOnly}
          onClick={() => setMenuOpen(true)}
        >
          Add forecast
        </Button>
        <Menu
          open={menuOpen}
          anchorEl={menuAnchor.current}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={() => setMenuOpen(false)}
        >
          {options.map(({ year, disabled }) => (
            <MenuItem
              key={year}
              onClick={() => {
                addForecast(year);
                setMenuOpen(false);
              }}
              disabled={disabled}
            >
              {year}
            </MenuItem>
          ))}
        </Menu>
      </InputLabel>
      {value.map((forecast) => {
        const onForecastChange = (newForecast: IForecast) => {
          onChange(
            value.map((f) => (f.year === forecast.year ? newForecast : f))
          );
        };
        return (
          <ForecastRow
            key={forecast.year}
            forecast={forecast}
            onChange={onForecastChange}
            deleteForecast={deleteForecast}
          />
        );
      })}
    </Stack>
  );
};
