import assertNever from 'assert-never';
import { compact, keyBy } from 'lodash';
import { useMemo } from 'react';
import { getAdvertiserColor } from '../../components/AdvertiserWithColor';
import {
  Data as EarningsBarChartData,
  limitAndSort
} from '../../components/Charts/EarningsChart';
import { CurrencyCode } from '../../domainTypes/currency';
import {
  EarningsArgs,
  EarningsArgsGroupedInTimeframeAsTimeseries,
  EarningsResp,
  EarningsRespGroupedInTimeframe,
  EarningsRespGroupedInTimeframeAsTimeseries,
  EarningsRespInTimeframe,
  EarningsRespInTimeframeAsTimeseries,
  EarningsRespTopPagesForOriginInTimeFrame,
  EARNING_MINIMAL_FIELD_SET,
  EMPTY_EARNINGS_MINIMAL_DAILY,
  IEarningMinimal,
  IEarningMinimalDaily,
  SalesFilterArgs,
  toDailyEarningFromMinimal
} from '../../domainTypes/performance';
import { mapValuesAsync } from '../../helpers';
import { usePromise } from '../../hooks/usePromise';
import { toChecksum } from '../checksum';
import { CURRENCY_CONVERTER } from '../currency/converter';
import { LoadingValue, useMappedLoadingValue } from '../db';
import { callFirebaseFunction } from '../firebaseFunctions';
import { getTimeKeyRangeFromTimeframe, msToTimeframe } from '../time';
import { getSalesCacheforSpace, useSalesCacheContext } from './cache';

const convertEarningMinimal = async <T extends IEarningMinimal>(
  e: T,
  cur: CurrencyCode
): Promise<T> => {
  const conv = (x: number) =>
    x === 0
      ? 0
      : CURRENCY_CONVERTER.convert(x, e.cur, cur).then((t) => Math.round(t));

  return {
    ...e,
    cf: await conv(e.cf),
    cp: await conv(e.cp),
    cc: await conv(e.cc),
    crf: await conv(e.crf),
    crj: await conv(e.crj),
    cnc: await conv(e.cnc),
    cu: await conv(e.cu),
    cur
  };
};

const convertTopPagesForOriginInTimeframe = async (
  d: EarningsRespTopPagesForOriginInTimeFrame,
  cur: CurrencyCode
): Promise<EarningsRespTopPagesForOriginInTimeFrame> => {
  if (cur === d.q.currency) {
    return d;
  }
  return {
    ...d,
    d: await mapValuesAsync(d.d, async ({ curr, prev }) => ({
      curr: await convertEarningMinimal(curr, cur),
      prev: prev ? await convertEarningMinimal(prev, cur) : null
    }))
  };
};

const convertInTimeframe = async (
  d: EarningsRespInTimeframe,
  cur: CurrencyCode
): Promise<EarningsRespInTimeframe> => {
  if (cur === d.q.currency) {
    return d;
  }
  return {
    ...d,
    d: await convertEarningMinimal(d.d, cur)
  };
};

const padTimeseries = (
  tkRange: string[],
  series: IEarningMinimalDaily[],
  cur: CurrencyCode
) => {
  if (series.length === tkRange.length) {
    return series;
  }
  const dict = keyBy(series, (t) => t.tk);
  return tkRange.map((tk) => dict[tk] || EMPTY_EARNINGS_MINIMAL_DAILY(cur, tk));
};

// convert and make ranges continuous
const finalizeInTimeframeAsTimeseries = async (
  d: EarningsRespInTimeframeAsTimeseries,
  cur: CurrencyCode
): Promise<EarningsRespInTimeframeAsTimeseries> => {
  const tkRange = getTimeKeyRangeFromTimeframe(msToTimeframe(d.q.dates));
  const series =
    cur === d.q.currency
      ? d.d
      : await Promise.all(d.d.map((x) => convertEarningMinimal(x, cur)));
  return {
    ...d,
    d: padTimeseries(tkRange, series, cur)
  };
};

const convertGroupedInTimeframe = async (
  d: EarningsRespGroupedInTimeframe,
  cur: CurrencyCode
): Promise<EarningsRespGroupedInTimeframe> => {
  if (cur === d.q.currency) {
    return d;
  }
  return {
    ...d,
    d: await Promise.all(
      d.d.map(async (x) => ({
        ...x,
        d: await convertEarningMinimal(x.d, cur)
      }))
    )
  };
};

// convert and make ranges continuous
const finalizeGroupedInTimeframeAsTimeseries = async (
  d: EarningsRespGroupedInTimeframeAsTimeseries,
  cur: CurrencyCode
): Promise<EarningsRespGroupedInTimeframeAsTimeseries> => {
  const tkRange = getTimeKeyRangeFromTimeframe(msToTimeframe(d.q.dates));
  return {
    ...d,
    d: await Promise.all(
      d.d.map(async (x) => {
        const series =
          cur === d.q.currency
            ? x.ds
            : await Promise.all(x.ds.map((y) => convertEarningMinimal(y, cur)));
        return {
          ...x,
          ds: padTimeseries(tkRange, series, cur)
        };
      })
    )
  };
};

const USE_REPORTING_CURRENCY = true;

const _getEarnings = <T extends EarningsResp[]>(
  spaceId: string,
  queries: EarningsArgs[]
) => {
  return callFirebaseFunction<T>('sales-getEarnings', {
    spaceId,
    qs: USE_REPORTING_CURRENCY
      ? queries
      : queries.map((q) => ({
          ...q,
          d: {
            ...q.d,
            currency: 'USD'
          }
        }))
  });
};

export const getEarnings = async <T extends EarningsResp[]>(
  spaceId: string,
  queries: EarningsArgs[]
): Promise<T> => {
  const cache = getSalesCacheforSpace(spaceId).earnings;
  const withChecksums = queries.map((q) => ({
    checksum: toChecksum({ spaceId, q }),
    q
  }));
  const uncached = withChecksums.filter((x) => !cache[x.checksum]);
  if (uncached.length) {
    // console.log(
    //   'UNCACHED',
    //   uncached.map((x) => x.checksum),
    //   cache,
    //   spaceId
    // );
    const promise = _getEarnings<T>(
      spaceId,
      uncached.map((x) => x.q)
    );
    uncached.forEach((x, i) => {
      cache[x.checksum] = promise.then((r) => r[i]);
    });
  }
  return ((await Promise.all(
    withChecksums.map(async (x) => await cache[x.checksum])
  )) as unknown) as T;
};

export const useEarnings = <T extends EarningsResp[]>(
  spaceId: string,
  queries: EarningsArgs[],
  currency: CurrencyCode
) => {
  const { version } = useSalesCacheContext();
  return usePromise<{
    time: number;
    res: T;
  }>(() => {
    const n = Date.now();
    return getEarnings<T>(spaceId, queries).then(async (res) => {
      return {
        time: Date.now() - n,
        res: (await Promise.all(
          res.map<Promise<EarningsResp>>(async (r) => {
            switch (r.type) {
              case 'topPagesForOriginInTimeframe':
                return convertTopPagesForOriginInTimeframe(r, currency);
              case 'inTimeframe':
                return convertInTimeframe(r, currency);
              case 'inTimeframeAsTimeseries':
                return finalizeInTimeframeAsTimeseries(r, currency);
              case 'groupedInTimeframe':
                return convertGroupedInTimeframe(r, currency);
              case 'groupedInTimeframeAsTimeseries':
                return finalizeGroupedInTimeframeAsTimeseries(r, currency);
              default:
                return assertNever(r);
            }
          })
        )) as any // too tough to type - giving up.
      };
    });
  }, [spaceId, queries, currency, version]);
};

export const useEarningsSingle = <T extends EarningsResp>(
  spaceId: string,
  query: EarningsArgs,
  currency: CurrencyCode
) => {
  const queries = useMemo(() => [query], [query]);
  return useMappedLoadingValue(
    useEarnings<[T]>(spaceId, queries, currency),
    ({ time, res }) => ({ time, res: res[0] })
  );
};

export const useAdvertiserTimeseriesData = (
  spaceId: string,
  q: SalesFilterArgs,
  currency: CurrencyCode,
  search?: string
): LoadingValue<{
  dates: { start: number; end: number; tz: string };
  data: EarningsBarChartData[];
}> => {
  const query: EarningsArgsGroupedInTimeframeAsTimeseries = useMemo(() => {
    return {
      type: 'groupedInTimeframeAsTimeseries',
      d: {
        groupBy: ['advertiser_name', 'partner_key'],
        fields: EARNING_MINIMAL_FIELD_SET.COMMISSIONS_VOLUME_AND_TX_COUNT,
        ...q,
        currency
      }
    };

    /*
     * Eventually the search should be performed on the server, but we would
     * need to define that only the advertiser name should be searched, and
     * right now the search applies to all columns, which leads to unexpected
     * views in the frontend
     */
  }, [q, currency, search]); // eslint-disable-line react-hooks/exhaustive-deps
  return useMappedLoadingValue(
    useEarningsSingle<EarningsRespGroupedInTimeframeAsTimeseries>(
      spaceId,
      query,
      currency
    ),
    (r) => {
      console.log('advertiser timeseries', r);
      return {
        dates: r.res.q.dates,
        data: limitAndSort(
          compact(
            r.res.d.map<EarningsBarChartData | null>((x) => {
              const advertiserName: string = x.group['advertiser_name'];
              const partnerKey: string = x.group['partner_key'];

              return {
                container: {
                  key: `${advertiserName}-${partnerKey}`,
                  pk: partnerKey,
                  color: getAdvertiserColor(advertiserName, partnerKey),
                  label: advertiserName
                },
                earnings: x.ds.map(toDailyEarningFromMinimal)
              };
            })
          ).filter((d) => {
            return search
              ? d.container.key.toLocaleLowerCase().includes(search)
              : true;
          }),
          15
        )
      };
    }
  );
};
