import { Drawer, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { capitalize, maxBy, minBy, times } from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { ExternalLink, Image, X } from 'react-feather';
import {
  Dot,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis
} from 'recharts';
import { useTimeframe } from '../../../components/analytics_v2/Timeframe';
import {
  CustomTooltipWithSinglePayload,
  TooltipHeading
} from '../../../components/Charts/CustomTooltip';
import {
  formatChartCurrency,
  WithShape
} from '../../../components/Charts/Util';
import {
  DrawerHeader,
  DrawerSection,
  List,
  SectionHeading
} from '../../../components/DrawerHeader';
import { LinkExternal } from '../../../components/LinkExternal';
import {
  clampCents,
  Currency,
  formatCurrency,
  Price
} from '../../../components/Number';
import { Dash } from '../../../components/Table/CountCell';
import { Truncated } from '../../../components/Truncated';
import {
  AnalyticsQuery,
  ISOTimeRange
} from '../../../domainTypes/analytics_v2';
import { CurrencyCode } from '../../../domainTypes/currency';
import {
  IProductCatalogHistoryRowInTimeseries,
  ProductCatalogAvailability,
  ProductCatalogHistoryInterval
} from '../../../domainTypes/productCatalog';
import { css, styled } from '../../../emotion';
import { FlexContainer, FlexContainerVertical } from '../../../layout/Flex';
import { useChannelIdGrouper } from '../../../services/analyticsV2/groups';
import { useAnalyticsQueryV2 } from '../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../services/currentUser';
import { fromMoment } from '../../../services/time';
import { getPathname } from '../../../services/url';
import { useSpaceCurrency } from '../../../services/useSpaceCurrency';
import {
  useProductCatalogHistoryWithDefaults,
  useProductCatalogItem,
  useProductCatalogMatchingProductsById
} from '../../ProductCatalog/service';
import {
  getAvailabilityColors,
  getAvailabilityTexts,
  IssueAvailability,
  IssueAvailabilityBadge
} from '../pages/Overview/components/ProductIssue';

const InnerWrapper = styled('div')`
  max-width: 400px;
`;

const IMAGE_SIZE = 100;

const ProductImageContainer = styled('div')`
  border: 1px solid ${(p) => p.theme.palette.grey[300]};
  box-sizing: border-box; /* Include padding in size */
  width: ${IMAGE_SIZE}px;
  height: ${IMAGE_SIZE}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${(p) => p.theme.palette.common.white};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

  color: ${(p) => p.theme.palette.grey[400]}; /* for fallback icon */

  img {
    display: block; /* Prevent inline element margins */
    object-fit: contain; /* Maintain aspect ratio */
    width: ${IMAGE_SIZE - 4}px;
    height: ${IMAGE_SIZE - 4}px;
  }
`;

const Grid = styled('div')`
  display: grid;
  grid-template-columns: ${IMAGE_SIZE}px 1fr;
  gap: 1rem;
`;

const AltRetailersGrid = styled('div')`
  display: grid;
  justify-content: space-between;
  width: 100%;
  grid-template-columns: 33% 33% 33%;
`;

const LoadingProductSummary = () => {
  return (
    <>
      <DrawerSection>
        <Skeleton variant="text" height={36} width={72} />
        <Typography variant="h5" component="p">
          <Skeleton variant="text" height={41} />
          <Skeleton variant="text" height={41} />
          <Skeleton variant="text" height={41} width={300} />
        </Typography>
      </DrawerSection>

      <DrawerSection>
        <Grid>
          <Skeleton variant="rect" width={IMAGE_SIZE} height={IMAGE_SIZE} />
          <List>
            <dt>Price</dt>
            <dd>
              <Skeleton variant="text" height={24} width={80} />
            </dd>
            <dt>On sale</dt>
            <dd>
              <Skeleton variant="text" height={24} width={40} />
            </dd>
            <dt>Last updated</dt>
            <dd>
              <Skeleton variant="text" height={24} width={72} />
            </dd>
          </List>
        </Grid>
      </DrawerSection>
    </>
  );
};

const ProductSummary = ({ uid }: { uid: string }) => {
  const [product] = useProductCatalogItem(uid);
  return (
    <DrawerSection>
      {!product && <LoadingProductSummary />}
      {product && (
        <>
          <IssueAvailabilityBadge
            availability={product.availability}
            // These are currently returned in a YYYY-MM-DD HH:mm:ss format.
            // We're gonna change this most likely to be true ISO formats,
            // which might require changes of this code.
            seenAt={fromMoment(moment.utc(product.seen_at))}
            availabilityChangeSeenAt={fromMoment(
              moment.utc(product.availability_change_seen_at)
            )}
          />
          <Typography variant="h5" paragraph style={{ marginTop: 8 }}>
            <strong>{product.title}</strong>{' '}
            <LinkExternal color="primary" href={product.link}>
              <ExternalLink size={20} />
            </LinkExternal>
          </Typography>
          <Grid>
            <ProductImageContainer>
              {product.image_link ? (
                <img src={product.image_link} alt={product.title} />
              ) : (
                <Image size={24} />
              )}
            </ProductImageContainer>
            <List>
              <dt>Price</dt>
              <dd>
                <Price
                  price={product.price}
                  orig_price={product.orig_price}
                  currency={product.price_curr as CurrencyCode}
                />
              </dd>
              <dt>On sale</dt>
              <dd>
                {product.price !== product.orig_price ? (
                  <Typography color="error" variant="body2">
                    Yes
                  </Typography>
                ) : (
                  'No'
                )}
              </dd>
              <dt>Last updated</dt>
              <dd>
                <Tooltip
                  title={moment.utc(product.seen_at).format('LLL')}
                  placement="top"
                >
                  <span>{moment.utc(product.seen_at).format('LT')}</span>
                </Tooltip>
              </dd>
            </List>
          </Grid>
        </>
      )}
    </DrawerSection>
  );
};

const PageUrlGrid = styled('div')`
  display: grid;
  grid-template-columns: 175px 1fr 1fr 1fr;
  gap: 1rem;
`;

const PagePerformance = ({ uid }: { uid: string }) => {
  const { space } = useCurrentUser();
  const currency = useSpaceCurrency();
  const { range, compare } = useTimeframe();
  const { columnTransformers } = useChannelIdGrouper();

  const pagePerformanceQuery = useMemo<AnalyticsQuery>(() => {
    return {
      select: ['c', 'epc_net', 'commission_sum_net'],
      range,
      compare,
      groupBy: ['page_url'],
      orderBy: [
        {
          field: 'c',
          direction: 'DESC'
        }
      ],
      filters: [
        {
          field: 'p_catalog_uid',
          condition: 'in',
          values: [uid]
        }
      ],
      paginate: {
        page: 1,
        limit: 10
      },
      columnTransformers: columnTransformers(space)
    };
  }, [range, columnTransformers, space, compare, uid]);

  const [data] = useAnalyticsQueryV2(space.id, pagePerformanceQuery, {
    logMode: 'full',
    logLabel: 'ProductCatalogDrawer'
  });

  return (
    <DrawerSection>
      <SectionHeading>Page performance</SectionHeading>
      <PageUrlGrid>
        <Typography variant="caption" color="textSecondary">
          Page URL
        </Typography>
        <Typography variant="caption" color="textSecondary">
          Clicks
        </Typography>
        <Typography variant="caption" color="textSecondary">
          EPC
        </Typography>
        <Typography variant="caption" color="textSecondary">
          Earnings
        </Typography>
        {!data &&
          times(4 * 6).map((i) => (
            <Skeleton key={i} variant="text" height={24} />
          ))}
        {data &&
          data.rows.map((row) => {
            const clicks = row.data.c?.curr ?? 0;
            const epc = row.data.epc_net?.curr ?? 0;
            const earnings = row.data.commission_sum_net?.curr ?? 0;

            return (
              <React.Fragment key={row.group.page_url}>
                <Truncated title={row.group.page_url}>
                  {getPathname(row.group.page_url)}
                </Truncated>
                <div>{clicks.toLocaleString()}</div>
                <div>
                  {epc !== 0 ? (
                    <Currency cents={epc} currency={currency} />
                  ) : (
                    <Dash size={14} />
                  )}
                </div>
                <div>
                  {earnings !== 0 ? (
                    <Currency cents={earnings} currency={currency} />
                  ) : (
                    <Dash size={14} />
                  )}
                </div>
              </React.Fragment>
            );
          })}
      </PageUrlGrid>
    </DrawerSection>
  );
};

export const ProductHistoryChart = ({
  ds,
  range,
  interval
}: {
  ds: IProductCatalogHistoryRowInTimeseries[];
  range: ISOTimeRange;
  interval: ProductCatalogHistoryInterval;
}) => {
  // This currently only works correctly with daily
  // intervals. With weeks we need to make sure that
  // we're following the same mechanics as PGs date_bin does

  if (!ds.length) {
    // empty state
    return null;
  }
  const currency = ds[0].price_curr;
  const tz = interval.tz;

  console.log('HISTORY', ds);
  // Line chart
  // lines should be straight
  // Indicate via icon if on sale?
  // indicate via color availability status
  return (
    <ResponsiveContainer aspect={2}>
      <LineChart
        data={ds}
        margin={{
          left: -8,
          right: 4,
          bottom: 8
        }}
      >
        <XAxis
          // Need to make this number based, so that it's spaced out
          // correctly
          dataKey={(d) => moment(d.interval).valueOf()}
          tick={{
            fill: '#9b9b9b',
            fontSize: 12
          }}
          angle={-45}
          type="number"
          tickFormatter={(d) => moment.tz(d, tz).format('MM/DD')}
          domain={[
            moment(range.start).tz(tz).toISOString(),
            moment(range.end).tz(tz).toISOString()
          ]}
          textAnchor="end"
        />
        <YAxis
          tickFormatter={(d) =>
            formatChartCurrency(d, currency as CurrencyCode)
          }
          tick={{
            fill: '#9b9b9b',
            fontSize: 12
          }}
          domain={[
            Math.max(
              0,
              clampCents((minBy(ds, (d) => d.price)?.price || 0) - 1000, -1000)
            ),
            clampCents((maxBy(ds, (d) => d.price)?.price || 0) + 1000, 1000)
          ]}
        />
        <Line
          dataKey="price"
          stroke={'#9b9b9b'}
          isAnimationActive={false}
          dot={(d) => {
            const av = d.payload.availability as ProductCatalogAvailability;
            const avColors = getAvailabilityColors(av);
            return (
              <Dot
                r={d.r}
                cx={d.cx}
                cy={d.cy}
                className={css(() => ({
                  stroke: avColors.backgroundColor,
                  fill: avColors.backgroundColor
                }))}
              />
            );
          }}
        />
        <RechartsTooltip
          cursor={false}
          content={
            <CustomTooltipWithSinglePayload
              render={(d: IProductCatalogHistoryRowInTimeseries) => (
                <>
                  <TooltipHeading>
                    {moment(d.interval).tz(tz).format('MMM DD')}
                  </TooltipHeading>
                  <div>
                    <WithShape
                      color={
                        getAvailabilityColors(d.availability).backgroundColor
                      }
                    >
                      {getAvailabilityTexts(d.availability).label}
                    </WithShape>
                  </div>
                  <FlexContainer fullWidth spacing={1}>
                    <div>Price:</div>
                    <div>
                      <FlexContainer alignItems="flex-end">
                        <Price
                          price={d.price}
                          orig_price={d.orig_price}
                          currency={d.price_curr as CurrencyCode}
                          salePriceFirst={false}
                        />
                      </FlexContainer>
                    </div>
                  </FlexContainer>
                </>
              )}
            />
          }
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export const ProductHistory = ({ uid, tz }: { uid: string; tz: string }) => {
  const [history] = useProductCatalogHistoryWithDefaults(uid, tz);
  return (
    <DrawerSection>
      <SectionHeading>Price &amp; Stock History</SectionHeading>
      <br />
      {!history && <Skeleton variant="rect" height={172} />}
      {history && (
        <ProductHistoryChart
          ds={history.timeseries}
          range={history.range}
          interval={history.interval}
        />
      )}
    </DrawerSection>
  );
};

const ProductMatches = ({ uid }: { uid: string }) => {
  const [d] = useProductCatalogMatchingProductsById(uid);
  return (
    <DrawerSection>
      <SectionHeading>Also available at</SectionHeading>
      <FlexContainerVertical fullWidth>
        {!d &&
          times(3).map((i) => (
            <FlexContainer fullWidth key={i}>
              <Skeleton variant="text" height={24} width={50} />
              <Skeleton variant="text" height={24} width={30} />
              <Skeleton variant="rect" height={24} width={16} />
            </FlexContainer>
          ))}
        {d && !!d.directMatches.length && (
          <AltRetailersGrid>
            <Typography variant="caption" color="textSecondary" gutterBottom>
              Advertiser
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              gutterBottom
              style={{ textAlign: 'right' }}
            >
              Price
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              gutterBottom
              style={{ textAlign: 'right' }}
            >
              Stock
            </Typography>
            {d.directMatches.map((m) => {
              return (
                <React.Fragment key={m.uid}>
                  <LinkExternal
                    href={m.link}
                    color="primary"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px'
                    }}
                  >
                    {capitalize(m.retailer)} <ExternalLink size={16} />
                  </LinkExternal>
                  <div style={{ textAlign: 'right' }}>
                    {formatCurrency(m.price, m.price_curr as CurrencyCode)}
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IssueAvailability
                      availability={
                        m.availability as ProductCatalogAvailability
                      }
                      // These are currently returned in a YYYY-MM-DD HH:mm:ss format.
                      // We're gonna change this most likely to be true ISO formats,
                      // which might require changes of this code.
                      seenAt={fromMoment(moment.utc(m.seen_at))}
                      availabilityChangeSeenAt={fromMoment(
                        moment.utc(m.availability_change_seen_at)
                      )}
                    />
                  </div>
                </React.Fragment>
              );
            })}
          </AltRetailersGrid>
        )}
        {d && !d.directMatches.length && <div>-</div>}
      </FlexContainerVertical>
    </DrawerSection>
  );
};

export const ProductCatalogDrawer = ({
  id: uid,
  open,
  onClose
}: {
  id: string;
  open: boolean;
  onClose: () => void;
}) => {
  const { space } = useCurrentUser();
  const tz = space.config.tz;
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <DrawerHeader>
        <Typography variant="body1" component="span">
          Product catalog
        </Typography>
        <IconButton onClick={onClose}>
          <X size={16} />
        </IconButton>
      </DrawerHeader>
      <InnerWrapper>
        {uid && (
          <div>
            <ProductSummary uid={uid} />
            <ProductMatches uid={uid} />
            <ProductHistory uid={uid} tz={tz} />
            <PagePerformance uid={uid} />
          </div>
        )}
      </InnerWrapper>
    </Drawer>
  );
};
