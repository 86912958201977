import React from 'react';
import { Helmet } from 'react-helmet';
import { NoPermissions } from '../../../../components/NoPermissions';
import { useHasCurrentUserRequiredScopes } from '../../../../services/currentUser';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { InventoryContent } from './InventoryContent';

export const PageLinksInventory = () => {
  const [canView] = useHasCurrentUserRequiredScopes(['reports.links.view']);
  useTrackMixpanelView('view_links_inventory');

  return (
    <>
      <Helmet>
        <title>Inventory | Affilimate</title>
      </Helmet>
      {canView ? <InventoryContent /> : <NoPermissions />}
    </>
  );
};
